export interface changeLog {
    date: string,
    changes: string[]
}

export const ChangeLogs = [
    {
        date: '2024-10-03',
        changes: [
            'Added new outposts',
            'Added Drone spawn south of Boreas',
            'Other spawn fixes'
        ]
    },
    {
        date: '2024-09-13',
        changes: [
            'Added new outposts',
            'Adjusted Faucervix spawns',
            'Added Cyclop\'s Depths spawns',
            'Improve Primordial Longu spawn around East Scylla',
            'Added more spawns to Amethera'
        ]
    },
    {
        date: '2024-09-06',
        changes: [
            'Fixed Equus link to wiki',
            'Added Soniq Squid spawn and mix',
            'Fixed Falxangius Maturity in Vermillion Fields Land Area',
            'Added Leviathan spawns south of New Treasure Island',
            'Added Rippersnapper spawn around Genesis Amethera Headquarters'
        ]
    },
    {
        date: '2024-09-02',
        changes: [
            'Improved spawn area accuracy for Fugabarba by Camp Echidna',
            'Added outpost Tethys Creek',
            'Adjusted spawn area around Tethys Creek',
            'Added more spawns to Amethera continent',
            'Fixed Takuta Plateau border',
            'Added a link on the creature name in details header that links to wiki',
            'Added big Pupugis, Caperon Mature & Old spawn',
            'Updated spawn mix by Warrior Elites in Zychion'
        ]
    },
    {
        date: '2024-08-25',
        changes: [
            'Added Fungoid to Daily Mission hunting 2',
            'Added Atrax and Chirpy mix to a Mermoth spawn',
            'Adjusted a Igni spawn min maturity',
            'Adjusted spawn navigation offsets',
        ]
    }
]
