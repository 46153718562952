import React, { forwardRef, useMemo, useState, useEffect } from 'react';
import uniqid from 'uniqid';
import styled from '@emotion/styled';

export enum InputTypes {
  Text = 'text',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Dropdown = 'select'
}

export enum InputSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  marginTop: 15,
  marginBottom: 5
});

const InputField = styled.input<{ inputSize: InputSize }>(({ theme, inputSize }: any) => ({
  minHeight: 35,
  minWidth: inputSize === InputSize.Large ? 350 : inputSize === InputSize.Medium ? 250 : inputSize === InputSize.Small ? 150 : '100%',
  maxWidth: '100%',
  border: `1px solid ${theme.colors.grey}`,
  borderRadius: 5,
  padding: '0px 5px',
  margin: '0px 10px',
  boxShadow: '1px 1px 3px 0px grey',

  '&:focus': {
    boxShadow: `0px 0px 1px 1px ${theme.colors.gold}`,
    outline: 'none'
  },

  [theme.breakpoints.mobileAndLower]: {
    minWidth: 100
  }
}));

const Radio = styled.input(({ theme }: any) => ({
  width: 20,
  height: 20,

  '&:focus': {
    boxShadow: `0px 0px 1px 1px ${theme.colors.gold}`,
    outline: 'none'
  }
}));

const Submit = styled.input<{ disabled?: boolean }>(({ theme, disabled }: any) => ({
  width: 200,
  height: 35,
  border: `1px solid ${theme.colors.grey}`,
  borderRadius: 5,
  padding: '0px 5px',
  margin: '0px 10px',
  boxShadow: '1px 1px 3px 0px grey',
  textAlign: 'center',
  backgroundColor: disabled ? theme.colors.grey : theme.colors.white,
  opacity: disabled ? 0.7 : 1,

  '&:focus': {
    boxShadow: `0px 0px 1px 1px ${theme.colors.gold}`,
    outline: 'none'
  },

  '&:hover': {
    cursor: 'pointer'
  },

  [theme.breakpoints.mobileAndLower]: {
    minWidth: 100
  }
}));

const RadioButtonField = styled.div({
  display: 'flex',
  flexDirection: 'row',
  maxHeight: 40,

  '> span': {
    margin: 'auto 0',
    verticalAlign: 'middle',
    fontWeight: 900
  }
});

const RadioButtonLabel = styled.span<{ disabled?: boolean }>(({ disabled }) => ({
  opacity: disabled ? 0.5 : 1
}));

const Label = styled.label<{ disabled?: boolean }>(({ disabled }) => ({
  fontSize: 16,
  marginLeft: 15,
  opacity: disabled ? 0.5 : 1
}));

const Error = styled.span({
  color: 'red',
  marginLeft: 15
});

interface ITextInputProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  maxLength?: number;
  pattern?: string;
  min?: number;
  max?: number;
  value?: string;
  checked?: boolean;
  placeholder?: string;
  disabled?: boolean;
  inputSize?: InputSize;
  required?: boolean;
  error?: string | React.ReactNode;
  valid?: boolean;
  autoFocus?: boolean;
  step?: number;

  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onClick?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const Input = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      label,
      disabled,
      placeholder,
      value,
      checked,
      required,
      inputSize,
      error,
      valid,
      onBlur,
      onClick,
      onChange,
      autoFocus,
      min,
      max,
      type,
      step,
      ...rest
    },
    ref
  ) => {
    const id = useMemo(uniqid, []);
    const [isError, setIsError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }

      setIsDirty(true);
    };

    const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }

      setIsDirty(true);
    };

    useEffect(() => {
      if (!valid && required) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }, [isDirty, valid, error, required]);

    return (
      <Container>
        {type === 'radio' ? (
          <RadioButtonField>
            <Radio id={id} type={type} name={label} value={value} checked={checked} disabled={disabled} ref={ref} {...rest} />
            <RadioButtonLabel disabled={disabled}>{label}</RadioButtonLabel>
          </RadioButtonField>
        ) : type === 'submit' ? (
          <Submit defaultValue={value} disabled={disabled} onClick={onClick} />
        ) : (
          <>
            {label && (
              <Label disabled={disabled} htmlFor={id}>
                {label} {required && '*'}
              </Label>
            )}
            <InputField
              id={id}
              type={type ? type : 'text'}
              placeholder={placeholder ? placeholder : label}
              name={label}
              value={value}
              disabled={disabled}
              inputSize={inputSize ? inputSize : InputSize.Medium}
              aria-invalid={!valid}
              aria-describedby="error"
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              autoFocus={autoFocus}
              ref={ref}
              min={min}
              max={max}
              step={step}
              {...rest}
            />
            <Error id="error">{isError && isDirty && !valid ? (error ? error : 'Please input value') : ''}</Error>
          </>
        )}
      </Container>
    );
  }
);
