import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const blueColor = '#4b708f';

// const Destinations = [
//     'Arkadia',
//     'Calypso',
//     'Cyrene',
//     'Next Island',
//     'Rocktropia',
//     'Toulan',
// ];

// const ShipTypes = [
//     'Sleipnir',
//     'Mothership',
//     'Privateer',
//     'Quad-Wing',
// ]

const Main = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: 'rgba(20,20,20,1)',
    color: 'white',
});

const Content = styled.div({
});

const Title = styled.h2({
    fontSize: '2em',
});

const CurrentTime = styled.h2({
    fontSize: '1.3em',
});

const RegisterLink = styled.p({
    maxWidth: '90%',
    margin: '0 auto',
    fontSize: '0.8em',
    marginTop: '1.5em',

    a: {
        color: blueColor,
        filter: 'brightness(150%)'
    }
});

const GuestApplicationForm = styled.div({
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 99,
    backgroundColor: 'rgba(0,0,0,0.8)'
});;

const PilotRegistrationForm = styled.div({
    width: '100%',
    height: '100%',
    zIndex: 99,
    backgroundColor: 'rgba(0,0,0,0.5)'
});

const PilotTable = styled.table({
    padding: '1.5em',
    borderCollapse: 'collapse',
    fontFamily: 'sans-serif',
    minWidth: '350px',
    maxWidth: '90%',
    margin: '0 auto',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.8)',
    fontSize: '0.9em',
    borderRadius: '8px 8px 0 0',
    overflow: 'hidden',

    th: {
        height: '50px',
        padding: '10px',
        backgroundColor: blueColor,
        color: '#ffffff',
    },

    tbody: {
        tr: {
            position: 'relative',
            height: '3.5em',
            minHeight: '3.5em',
            borderBottom: 'thin solid #dddddd',

            '&:nth-of-type(even)': {
                backgroundColor: '#1e2125',
            },

            '&:last-of-type': {
                borderBottom: `2px solid ${blueColor}`
            },

            '&:hover': {
                //backgroundColor: '#222',
            },

            td: {
                border: 'none',
                padding: '10px 10px',

                button: {
                    height: '3em',
                    borderRadius: '5px',
                    border: 'none',
                    color: 'white',
                    backgroundColor: blueColor,

                    '&:hover': {
                        filter: 'brightness(85%)',
                        cursor: 'pointer'
                    }
                }
            }
        }
    }
});

const GuestFormContainer = styled.div({
    position: 'fixed',
    display: 'inline-block',
    top: '-20%',
    left: '0',
    bottom: '0',
    right: '0',
    margin: 'auto',
    width: '80%',
    height: 'fit-content',
    minWidth: '400px',
    maxWidth: '800px',
    maxHeight: '80%',
    backgroundColor: 'rgba(20,20,20,1)',
    color: 'white',
    borderBottom: `2px solid ${blueColor}`
});

const TicketTitle = styled.div({
    backgroundColor: blueColor,
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1em',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.8)',
    fontSize: '0.9em',
    borderRadius: '8px 8px 0 0',

    button: {
        position: 'absolute',
        opacity: 0,
        top: '3px',
        right: '2px',
        width: '2em',
        height: '2em',

        '&:hover': {
            cursor: 'pointer',
        }
    },

    '&::before': {
        position: 'absolute',
        content: '"X"',
        top: '4px',
        right: '9px',
        fontSize: '1.3em',
    }
});

const TitleDestination = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderBottom: 'thin solid white',
    minHeight: '70px',
    paddingLeft: '1em',
    paddingRight: '1em',
});

const TicketPilotedBy = styled.div({
    borderTop: '1px solid white',
    paddingLeft: '1em',
    paddingRight: '1em',
    marginTop: '1em',

    p: {
        fontSize: '0.8em',
    }
});

const TicketCostAndSign = styled.div({
    paddingLeft: '1em',
    paddingRight: '1em',

    input: {
        border: 'none',
        borderRadius: '8px',
        marginBottom: '0.9em',
        maxWidth: '400px',
        minWidth: '200px',
        width: '290px',
        height: '30px',
        minHeight: '30px',
        textAlign: 'center'
    },

    '> div': {
        button: {
            '&:first-of-type': {
                marginRight: '1em',
            },

            '&:hover': {
                filter: 'brightness(80%)',
                cursor: 'pointer',
            },

            '&:disabled': {
                filter: 'grayscale(80%)'
            },

            backgroundColor: blueColor,
            color: 'white',
            width: '70px',
            height: '35px',
            border: '1px solid black',
            borderRadius: '5px'
        }
    }
});

export const TaxiService: React.FC = () => {
    const [currentHours, setCurrentHours] = useState(new Date().getUTCHours());
    const [currentMinutes, setCurrentMinutes] = useState(new Date().getUTCMinutes());
    const [showApplication, setShowApplication] = useState(false);
    const [showPilotRegistration] = useState(false);
    const [gDep, setGDep] = useState('');
    const [gFrom, setGFrom] = useState('');
    const [gTo, setGTo] = useState('');
    const [gShip, setGShip] = useState('');
    const [gPilot, setGPilot] = useState('');
    const [gCost, setGCost] = useState('');
    const [gName, setGName] = useState('');
    const [validAvatarName, setValidAvatarName] = useState(false);
    const avatarNameRegex = /^(?=(?:[^\s]*\s){2})[\s\S]{10,}$/;

    useEffect(() => {
        if (gName.length > 0) {
            console.log("Testing name");
            setValidAvatarName(avatarNameRegex.test(gName));
            console.log("is name valid?", validAvatarName);
        }
    }, [setGName, gName, avatarNameRegex, validAvatarName]);

    const GuestApplication = () => {
        return <GuestApplicationForm>
            <GuestFormContainer>
                <TicketTitle>
                    <h3>Taxi ticket departing at <span>{gDep} UTC</span></h3>
                    <button onClick={() => setShowApplication(false)} />
                </TicketTitle>
                <TitleDestination>
                    <div>
                        <b>Departing from:</b>
                        <p>{gFrom}</p>
                    </div>
                    <div>
                        <p style={{ fontSize: '2em', margin: 0, lineHeight: '60px' }}>{'→'}</p>
                    </div>
                    <div>
                        <b>Arriving at:</b>
                        <p>{gTo}</p>
                    </div>
                </TitleDestination>
                <TicketCostAndSign>
                    <p>Total cost of flight to be paid upon departure: <b>{gCost}</b></p>
                    <div>
                        <p style={{ fontSize: '0.7em' }}>To complete your flight request please enter your full ingame name below and sign the application.</p>
                        <input type={'text'} onChange={e => setGName(e.currentTarget.value)} maxLength={50} placeholder='Full Ingame Name'></input>
                        <div>
                            <button onClick={() => setShowApplication(false)}>CANCEL</button>
                            <button disabled={!validAvatarName} onClick={() => console.log(gName)}>APPLY</button>
                        </div>
                    </div>
                </TicketCostAndSign>
                <TicketPilotedBy>
                    <p>This flight is piloted by <b><i>{gPilot}</i></b> and will be flown with a <b><i>{gShip}</i></b>, in the event that the pilot has space for your request they will contact you in-game before flight so make sure to be online please.</p>
                </TicketPilotedBy>
            </GuestFormContainer>
        </GuestApplicationForm>
    }

    useEffect(() => {
        const interval = setInterval(() => {
            var h = new Date().getUTCHours();
            var m = new Date().getUTCMinutes();

            setCurrentHours(h);
            setCurrentMinutes(m);
        }, 5000);
        return () => clearInterval(interval);
    }, [setCurrentHours, setCurrentMinutes]);

    return <Main>
        <Content>
            <Title>Taxi Flight Schedule</Title>
            <CurrentTime>Current UTC Time: {(currentHours < 10 ? `0${currentHours}` : currentHours) + ':' + (currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes)}</CurrentTime>
            {showApplication && GuestApplication()}
            {showPilotRegistration && <PilotRegistrationForm />}
            <PilotTable>
                <thead>
                    <tr>
                        <th>Departure (UTC)</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Ship</th>
                        <th>Pilot</th>
                        <th>Applications</th>
                        <th>Cost (PED)</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>15:00 / 3pm</td>
                        <td>Calypso</td>
                        <td>Rocktropia</td>
                        <td>Mothership</td>
                        <td>Ripcraze</td>
                        <td>32</td>
                        <td>9.00</td>
                        <td><button onClick={() => {
                            setShowApplication(true);
                            setGDep('15:00 / 3pm');
                            setGFrom('Calypso');
                            setGTo('Rocktropia');
                            setGShip('Mothership');
                            setGPilot('Ripcraze');
                            setGCost('9.00 PED');
                        }}>APPLY</button></td>
                    </tr>
                    <tr>
                        <td>02:00 / 2am</td>
                        <td>Toulan</td>
                        <td>Calypso</td>
                        <td>Sleipnir</td>
                        <td>Pepe</td>
                        <td>5</td>
                        <td>12.00</td>
                        <td><button onClick={() => {
                            setShowApplication(true);
                            setGDep('02:00 / 2am');
                            setGFrom('Toulan');
                            setGTo('Calypso');
                            setGShip('Sleipnir');
                            setGPilot('Pepe');
                            setGCost('12.00 PED');
                        }}>APPLY</button></td>
                    </tr>
                    <tr>
                        <td>11:00 / 11am</td>
                        <td>Arkadia</td>
                        <td>Cyrene</td>
                        <td>Quad-Wing</td>
                        <td>GigaChad</td>
                        <td>0</td>
                        <td>10.00</td>
                        <td><button onClick={() => {
                            setShowApplication(true);
                            setGDep('11:00 / 11am');
                            setGFrom('Arkadia');
                            setGTo('Cyrene');
                            setGShip('Quad-Wing');
                            setGPilot('GigaChad');
                            setGCost('10.00 PED');
                        }}>APPLY</button></td>
                    </tr>
                </tbody>
            </PilotTable>
            <RegisterLink>Are you a pilot and would like to register your ship as a available taxi vechile? Click <a href="/">HERE</a>. Applications for ship registrations will be checked on manually.</RegisterLink>
        </Content>
    </Main>
}