export const OilsAndResidues = [
    //Animal Oils & Residue
    {Name: 'Animal Adrenal Oil', Value: 0.2, Markup: 101},
    {Name: 'Animal Brain Oil', Value: 19, Markup: 110},
    {Name: 'Animal Eye Oil', Value: 0.05, Markup: 101},
    {Name: 'Animal Heart Oil', Value: 10, Markup: 105},
    {Name: 'Animal Kidney Oil', Value: 2, Markup: 101},
    {Name: 'Animal Liver Oil', Value: 1, Markup: 101},
    {Name: 'Animal Muscle Oil', Value: 0.03, Markup: 102},
    {Name: 'Animal Pancreas Oil', Value: 0.5, Markup: 101},
    {Name: 'Animal Spleen Oil', Value: 5, Markup: 105},
    {Name: 'Animal Thyroid Oil', Value: 0.1, Markup: 101},
    {Name: 'Animal Oil Residue', Value: 0.01, Markup: 100},
    {Name: 'Robot Component Residue', Value: 0.01, Markup: 100},
];

export const Consumables = [
    // Consumables
    {Name: 'Explosive Projectiles', Value: 0.0001, Markup: 103},
    {Name: 'Light Mind Essence', Value: 0.0001, Markup: 100},
    {Name: 'Mind Essence', Value: 0.0001, Markup: 100},
    {Name: 'Survey Probe', Value: 0.05, Markup: 100},
];

export const ElectronicComponents = [
    // Electronic Components
    {Name: 'Fuel Cells', Value: 0.05, Markup: 100},
    {Name: 'Adaptor', Value: 2.2, Markup: 100},
    {Name: 'Advanced Feedback Panel', Value: 0.17, Markup: 100},
    {Name: 'Advanced Matrix', Value: 0.24, Markup: 100},
    {Name: 'Advanced Cooling System Unit', Value: 1, Markup: 100},
    {Name: 'Advanced Modulator', Value: 0.08, Markup: 100},
    {Name: 'Advanced Processor', Value: 0.096, Markup: 100},
    {Name: 'Advanced Security Module', Value: 0.211, Markup: 100},
    {Name: 'Advanced VisioMem', Value: 0.072, Markup: 100},
    {Name: 'Basic Auxiliary Socket', Value: 0.0136, Markup: 100},
    {Name: 'Basic Power System', Value: 0.01, Markup: 100},
    {Name: 'Basic Processor', Value: 0.020797, Markup: 100},
    {Name: 'Basic Relay', Value: 0.05, Markup: 100},
    {Name: 'Basic Sensor', Value: 0.024, Markup: 100},
    {Name: 'Basic Wires', Value: 0.03, Markup: 100},
    {Name: 'Conduit', Value: 0.35, Markup: 100},
    {Name: 'Creative Juice', Value: 0.1, Markup: 100},
    {Name: 'Electropositive Capacitor', Value: 0.033, Markup: 100},
    {Name: 'Electropositive Communication', Value: 0.044, Markup: 100},
    {Name: 'Electropositive Connector', Value: 0.181818, Markup: 100},
    {Name: 'Electropositive Modulator', Value: 0.0208, Markup: 100},
    {Name: 'Electropositive Processor', Value: 0.416, Markup: 100},
    {Name: 'Electropositive Security Module', Value: 0.09, Markup: 100},
    {Name: 'Elysian Power Sphere', Value: 0.05, Markup: 100},
    {Name: 'Energy Chamber', Value: 2, Markup: 100},
    {Name: 'Feedback Coupling', Value: 2.5, Markup: 100},
    {Name: 'Focusing Lens', Value: 2, Markup: 100},
    {Name: 'Galaxy 6V Gel Batteries', Value: 1.2, Markup: 100},
    {Name: 'Galaxy 8V Gel Batteries', Value: 1.4, Markup: 100},
    {Name: 'Galaxy 12V Gel Batteries', Value: 1.5, Markup: 100},
    {Name: 'Galaxy 14V Gel Batteries', Value: 2.2, Markup: 100},
    {Name: 'Galaxy S1 Ion Conductors', Value: 0.2, Markup: 100},
    {Name: 'Galaxy S2 Ion Conductors', Value: 0.3, Markup: 100},
    {Name: 'Galaxy S3 Ion Conductors', Value: 0.5, Markup: 100},
    {Name: 'Insulators', Value: 0.2, Markup: 100},
    {Name: 'Intensity Amplifier', Value: 3.5, Markup: 100},
    {Name: 'Light Power Cell', Value: 0.05, Markup: 100},
    {Name: 'Lysterium Power Container', Value: 0.4, Markup: 100},
    {Name: 'Mechanomatrix Adapter Unit 1', Value: 0.3903, Markup: 100},
    {Name: 'Mechanomatrix Adapter Unit 2', Value: 0.75, Markup: 100},
    {Name: 'Mechanomatrix Adapter Unit 3', Value: 1.65, Markup: 100},
    {Name: 'Medium Grade Power Supply', Value: 0.048, Markup: 100},
    {Name: 'Medium Power Cell', Value: 0.5, Markup: 100},
    {Name: 'Monrian Power System', Value: 0.07, Markup: 100},
    {Name: 'Nanocube', Value: 0.01, Markup: 100},
    {Name: 'Ozpyn Matrix', Value: 0.03, Markup: 100},
    {Name: 'Power Regulator', Value: 5, Markup: 100},
    {Name: 'Projector Housing', Value: 1.5, Markup: 100},
    {Name: 'Simple 1 Conductors', Value: 0.3, Markup: 100},
    {Name: 'Simple 2 Conductors', Value: 0.65, Markup: 100},
    {Name: 'Simple 3 Conductors', Value: 1.1, Markup: 100},
    {Name: 'Simple 4 Conductors', Value: 1.5, Markup: 100},
    {Name: 'Solar 6V Gel Batteries', Value: 0.65, Markup: 100},
    {Name: 'Solar 8V Gel Batteries', Value: 0.7, Markup: 100},
    {Name: 'Solar 12V Gel Batteries', Value: 0.9, Markup: 100},
    {Name: 'Solar 14V Gel Batteries', Value: 1.65, Markup: 100},
    {Name: 'Solar 16V Gel Batteries', Value: 1.95, Markup: 100},
    {Name: 'Standard Feedback Panel', Value: 0.0452, Markup: 100},
    {Name: 'Standard Holo Module', Value: 0.084, Markup: 100},
    {Name: 'Standard Intelligence Module', Value: 0.17, Markup: 100},
    {Name: 'Standard Matrix', Value: 0.0512, Markup: 100},
    {Name: 'Standard Transparency Layer', Value: 0.0244, Markup: 100},
    {Name: 'Standard VisioMem', Value: 0.0204, Markup: 100},
    {Name: 'Stimulation Modulator', Value: 3, Markup: 100},
    {Name: 'Triphased Auxiliary Socket', Value: 0.052, Markup: 100},
    {Name: 'Triphased Holo Module', Value: 0.2, Markup: 100},
    {Name: 'Triphased Intelligence Module', Value: 0.5, Markup: 100},
    {Name: 'Triphased Power Systems', Value: 0.04, Markup: 100},
    {Name: 'Triphased Transmitter', Value: 0.04, Markup: 100},
    {Name: 'Triphased Wires', Value: 0.12, Markup: 100},
];

export const EnhancerComponents = [
    // Enhancer Components
    {Name: 'Adaptive Fire Rate Component', Value: 0.3, Markup: 100},
    {Name: 'Electronic Stabilizing Component', Value: 0.2, Markup: 100},
    {Name: 'Empty Enhancer Component', Value: 1, Markup: 100},
    {Name: 'Focus Lens Component', Value: 0.2, Markup: 100},
    {Name: 'High Definition GUI Component', Value: 0.3, Markup: 100},
    {Name: 'High Speed Control Component', Value: 0.1, Markup: 100},
    {Name: 'Infrasound Amplifier Component', Value: 0.5, Markup: 100},
    {Name: 'Infrasound Emitter Component', Value: 0.4, Markup: 100},
    {Name: 'Interactive Simplifier Component', Value: 0.3, Markup: 100},
    {Name: 'Material Efficiency Component', Value: 0.5, Markup: 100},
    {Name: 'Output Amplifier Component', Value: 0.4, Markup: 100},
    {Name: 'Rationalizer Component', Value: 0.3, Markup: 100},
    {Name: 'Socket 1 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 2 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 3 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 4 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 5 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 6 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 7 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 8 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 9 Component', Value: 0.1, Markup: 100},
    {Name: 'Socket 10 Component', Value: 0.1, Markup: 100},
    {Name: 'Sonic Pulse Amplifier', Value: 0.2, Markup: 100},
    {Name: 'Super Charger Component', Value: 0.4, Markup: 100},
    {Name: 'Surface Hardener Component', Value: 0.2, Markup: 100},
    {Name: 'Surface Toughness Component', Value: 0.3, Markup: 100},
];

export const Fragments = [
    // Fragments
    {Name: 'Blazar Fragment', Value: 0.00001, Markup: 100},
    {Name: 'Nova Fragment', Value: 0.00001, Markup: 100},
    {Name: 'Shrapnel', Value: 0.0001, Markup: 100.5},
];

export const GenericComponents = [
    // Generic Components
    {Name: 'Basic Propulsion System', Value: 15, Markup: 100},
    {Name: 'Basic Scanning Sensor', Value: 0.11, Markup: 100},
    {Name: 'Basic Strategic Combat Processor', Value: 0.04, Markup: 100},
    {Name: 'Bonding Liquid', Value: 0.02, Markup: 100},
    {Name: 'Generic Fuse', Value: 5, Markup: 100},
    {Name: 'Generic Transformer', Value: 5, Markup: 100},
    {Name: 'Light Metal Plating', Value: 0.5, Markup: 100},
    {Name: 'Material Softener', Value: 0.14, Markup: 100},
    {Name: 'Strengthened Metal Plating', Value: 0.83, Markup: 100},
];

export const MechnicalComponents = [
    // Mechanical Components
    {Name: 'Advanced Heavy Springs', Value: 1, Markup: 100},
    {Name: 'Advanced Spur Gears 6k', Value: 1, Markup: 100},
    {Name: 'Alloy Hinge', Value: 1.1, Markup: 100},
    {Name: 'Basic Coil', Value: 0.03, Markup: 100},
    {Name: 'Basic Filters', Value: 0.01, Markup: 100},
    {Name: 'Basic Servo', Value: 0.014, Markup: 100},
    {Name: 'Basic Structure', Value: 0.0236, Markup: 100},
    {Name: 'Basic Vehicle Battery', Value: 0.91, Markup: 100},
    {Name: 'Basic Vehicle Gearbox', Value: 1.63, Markup: 100},
    {Name: 'Basic Vehicle Servo', Value: 0.28, Markup: 100},
    {Name: 'Basic Vehicle Supercharger', Value: 0.51, Markup: 100},
    {Name: 'Basic Vehicle Suspension', Value: 0.76, Markup: 100},
    {Name: 'Durable Coil', Value: 0.066, Markup: 100},
    {Name: 'Durable Hood', Value: 0.0416, Markup: 100},
    {Name: 'Durable Structure', Value: 0.149, Markup: 100},
    {Name: 'GEC Coil Springs 1M', Value: 0.9, Markup: 100},
    {Name: 'GEC Coil Springs 2M', Value: 1.2, Markup: 100},
    {Name: 'GEC Spur Gears 1K', Value: 0.3, Markup: 100},
    {Name: 'GEC Spur Gears 2K', Value: 0.65, Markup: 100},
    {Name: 'GEC Spur Gears 4K', Value: 1.75, Markup: 100},
    {Name: 'GEC Spur Gears 5K', Value: 2, Markup: 100},
    {Name: 'Heavy Duty Core', Value: 0.05, Markup: 100},
    {Name: 'Heavy-Duty Seals', Value: 0.08, Markup: 100},
    {Name: 'High Performance Engine', Value: 0.32, Markup: 100},
    {Name: 'High Performance Vents', Value: 0.06, Markup: 100},
    {Name: 'O Rings', Value: 0.02, Markup: 100},
    {Name: 'Ozpyn Filters', Value: 0.23, Markup: 100},
    {Name: 'Polymer Rail', Value: 0.25, Markup: 100},
    {Name: 'Simple 1 Plastic Springs', Value: 0.4, Markup: 100},
    {Name: 'Simple 2 Plastic Springs', Value: 0.75, Markup: 100},
    {Name: 'Simple 3 Plastic Springs', Value: 1.3, Markup: 100},
    {Name: 'Standard Compressor', Value: 0.0272, Markup: 100},
    {Name: 'Standard Vents', Value: 0.0168, Markup: 100},
];

export const MetalComponents = [
    // Metal Components
    {Name: 'Basic Bearings', Value: 0.04, Markup: 100},
    {Name: 'Basic Nuts', Value: 0.01, Markup: 100},
    {Name: 'Basic Rings', Value: 0.03, Markup: 100},
    {Name: 'Basic Screws', Value: 0.04, Markup: 100},
    {Name: 'Basic Sheet Metal', Value: 0.0392, Markup: 100},
    {Name: 'Hardened Alloy', Value: 0.0416, Markup: 100},
    {Name: 'Hardened Clips', Value: 0.0624, Markup: 100},
    {Name: 'Hardened Foil', Value: 0.033, Markup: 100},
    {Name: 'Hardened Metal Mountings', Value: 0.65, Markup: 100},
    {Name: 'Hardened Metal Ruds', Value: 0.4, Markup: 100},
    {Name: 'Hardened Nuts', Value: 0.033, Markup: 100},
    {Name: 'Hardened Screws', Value: 0.08, Markup: 100},
    {Name: 'High Grade Bearings', Value: 0.32, Markup: 100},
    {Name: 'High Grade Plugs', Value: 0.3, Markup: 100},
    {Name: 'Metal Mountings', Value: 0.6, Markup: 100},
    {Name: 'Metal Ruds', Value: 0.35, Markup: 100},
    {Name: 'Reinforced Bearings', Value: 0.203, Markup: 100},
    {Name: 'Reinforced Plate', Value: 0.04, Markup: 100},
    {Name: 'Reinforced Rod', Value: 0.067, Markup: 100},
    {Name: 'Reinforced Sheet Metal', Value: 0.157, Markup: 100},
    {Name: 'Simple 1 Plastic Ruds', Value: 0.5, Markup: 100},
    {Name: 'Simple 2 Plastic Ruds', Value: 0.95, Markup: 100},
    {Name: 'Simple 3 Plastic Ruds', Value: 1.4, Markup: 100},
    {Name: 'Standard Lever', Value: 0.0192, Markup: 100},
    {Name: 'Standard Plate', Value: 0.0128, Markup: 100},
    {Name: 'Standard Rod', Value: 0.0284, Markup: 100},
    {Name: 'Super Alloy Mountings', Value: 0.7, Markup: 100},
    {Name: 'Weak Magnets', Value: 0.12, Markup: 100},
];

export const NaturalMaterials = [
    // Natural Materials
    {Name: 'Alien Blood', Value: 0.46, Markup: 100},
    {Name: 'Alien Resin Fluid', Value: 0.12, Markup: 100},
    {Name: 'Animal Hide', Value: 0.01, Markup: 100},
    {Name: 'Aurli Bone Piece', Value: 7.4, Markup: 100},
    {Name: 'Aurli Chitin Scale', Value: 4, Markup: 100},
    {Name: 'Aurli Claw', Value: 4.5, Markup: 100},
    {Name: 'Aurli Dense Plate', Value: 4.5, Markup: 100},
    {Name: 'Aurli Fangs', Value: 1.8, Markup: 100},
    {Name: 'Aurli Shock Flesh', Value: 0.78, Markup: 100},
];

export const RobotComponents = [
    // Robot Components
    {Name: 'Robot Auto Adjuster', Value: 1.8, Markup: 100},
    {Name: 'Robot Buffers', Value: 0.77, Markup: 100},
    {Name: 'Robot Casing', Value: 1.01, Markup: 100},
    {Name: 'Robot Filter', Value: 0.036, Markup: 100},
    {Name: 'Robot Flare Filter', Value: 4.6, Markup: 100},
    {Name: 'Robot Heat Sinks', Value: 0.08, Markup: 100},
    {Name: 'Robot Hyper Charger', Value: 3.9, Markup: 100},
    {Name: 'Robot Laser Optics Unit', Value: 0.45, Markup: 100},
    {Name: 'Robot Low-Loss Link Cable', Value: 0.024, Markup: 100},
    {Name: 'Robot Optical Lens', Value: 0.89, Markup: 100},
    {Name: 'Robot Power Amplifier', Value: 2.7, Markup: 100},
    {Name: 'Robot Safety System', Value: 0.12, Markup: 100},
    {Name: 'Robot Weapon Grip', Value: 0.058, Markup: 100},
    {Name: 'Robot Weapon Sight', Value: 0.21, Markup: 100},
];

export const VehicleComponents = [
    // Vehicle Components
    {Name: 'Chair Frame', Value: 0.4, Markup: 100},
    {Name: 'Combustor', Value: 0.3, Markup: 100},
    {Name: 'Disc Brake', Value: 0.6, Markup: 100},
    {Name: 'Drive Shaft', Value: 0.9, Markup: 100},
    {Name: 'Girder Beams', Value: 0.7, Markup: 100},
    {Name: 'Lamp Attachments', Value: 0.3, Markup: 100},
    {Name: 'Radiator', Value: 0.4, Markup: 100},
    {Name: 'Rubber Lists', Value: 0.4, Markup: 100},
    {Name: 'Steering Rod', Value: 0.5, Markup: 100},
    {Name: 'Wind Screen', Value: 0.9, Markup: 100},
];

export const RefinedEnmatter = [
    // Refined Enmatter
    {Name: 'Alicenies Gel', Value: 0.1, Markup: 100},
    {Name: 'Angelic Flakes', Value: 1, Markup: 100},
    {Name: 'Antimagnetic Oil', Value: 2, Markup: 100},
    {Name: 'Ares Powder', Value: 0.52, Markup: 100},
    {Name: 'Aurilinin Gel', Value: 4.04, Markup: 100},
    {Name: 'Binary Energy', Value: 1.5, Markup: 100},
    {Name: 'Black Russian Cocktail', Value: 0.06, Markup: 100},
    {Name: 'Chalmon', Value: 0.94, Markup: 100},
    {Name: 'Dianthus Crystal Powder', Value: 0.6, Markup: 100},
    {Name: 'Distilled Sweat Crystal', Value: 1.1106, Markup: 100},
    {Name: 'Dunkel Plastix', Value: 1.1, Markup: 100},
    {Name: 'Edres Varnish', Value: 0.26, Markup: 100},
    {Name: 'Energized Crystal Cell', Value: 0.6, Markup: 100},
    {Name: 'Fire Root Pellet', Value: 0.6, Markup: 100},
    {Name: 'Florican Spray', Value: 0.54, Markup: 100},
    {Name: 'Garcen Lubricant', Value: 0.2, Markup: 100},
    {Name: 'Ghali Powder', Value: 0.32, Markup: 100},
    {Name: 'Hardening Agent', Value: 0.04, Markup: 100},
    {Name: 'Henren Cube', Value: 1.26, Markup: 100},
    {Name: 'Hurricane Cocktail', Value: 0.02, Markup: 100},
    {Name: 'Hydrogen Gas', Value: 0.04, Markup: 100},
    {Name: 'Light Liquid', Value: 0.84, Markup: 100},
    {Name: 'Light Mail', Value: 0.02, Markup: 100},
    {Name: 'Lytairian Powder', Value: 0.38, Markup: 100},
    {Name: 'Magerian Spray', Value: 0.5, Markup: 100},
    {Name: 'Mai Tai Cocktail', Value: 0.03, Markup: 100},
    {Name: 'Mamnoon Mist', Value: 0.16, Markup: 100},
    {Name: 'Medical Compress', Value: 0.18, Markup: 100},
    {Name: 'Melchi Crystal', Value: 0.04, Markup: 100},
    {Name: 'Mojito Cocktail', Value: 0.04, Markup: 100},
    {Name: 'Nirvana Cocktail', Value: 0.04, Markup: 100},
    {Name: 'Oil', Value: 0.02, Markup: 100},
    {Name: 'Pearl Sand', Value: 1.92, Markup: 100},
    {Name: 'Pel Liquid', Value: 1, Markup: 100},
    {Name: 'Pina Colada Cocktail', Value: 0.05, Markup: 100},
    {Name: 'Putty', Value: 0.78, Markup: 100},
    {Name: 'Quil Rubber', Value: 0.5, Markup: 100},
    {Name: 'Root Acid', Value: 0.64, Markup: 100},
    {Name: 'Sham Sand', Value: 0.01, Markup: 100},
    {Name: 'Softening Agent', Value: 0.14, Markup: 100},
    {Name: 'Solis Paste', Value: 1.56, Markup: 100},
    {Name: 'Somin Glue', Value: 0.12, Markup: 100},
    {Name: 'Star Dust', Value: 0.16, Markup: 100},
    {Name: 'Super Adhesive', Value: 0.02, Markup: 100},
    {Name: 'Typonolic Gas', Value: 0.3, Markup: 100},
    {Name: 'Vorn Plastic', Value: 0.4, Markup: 100},
    {Name: 'Whiskey Sour Cocktail', Value: 0.15, Markup: 100},
    {Name: 'Xelo Vapour', Value: 0.78, Markup: 100},
    {Name: 'Youd Bottle', Value: 0.08, Markup: 100},
    {Name: 'Zoldenite Crystal', Value: 0.08, Markup: 100},
    {Name: 'Zolphic Grease', Value: 0.08, Markup: 100},
];

export const RefinedOre = [
    // Refined Ore
    {Name: 'Adomasite Ingot', Value: 1.8, Markup: 100},
    {Name: 'Alferix Ingot', Value: 2.85, Markup: 100},
    {Name: 'Alternative Ingot', Value: 0.03, Markup: 100},
    {Name: 'Aqeeq Ingot', Value: 0.06, Markup: 100},
    {Name: 'Arekite Ingot', Value: 0.3, Markup: 100},
    {Name: 'Aumorphite Ingot', Value: 8.09, Markup: 100},
    {Name: 'Azzurdite Ingot', Value: 3.6, Markup: 100},
    {Name: 'Banite Ingot', Value: 0.24, Markup: 100},
    {Name: 'Belkar Ingot', Value: 0.06, Markup: 100},
    {Name: 'Blausariam Ingot', Value: 0.12, Markup: 100},
    {Name: 'Blues Ingot', Value: 0.06, Markup: 100},
    {Name: 'Caldorite Ingot', Value: 0.51, Markup: 100},
    {Name: 'Cobalt Ingot', Value: 0.6, Markup: 100},
    {Name: 'Cobaltium Ingot', Value: 2.001, Markup: 100},
    {Name: 'Copper Ingot', Value: 0.48, Markup: 100},
    {Name: 'Cordul Ingot', Value: 0.6, Markup: 100},
    {Name: 'Cumbriz Ingot', Value: 0.45, Markup: 100},
    {Name: 'Dark Lysterium Bar', Value: 0.06, Markup: 100},
    {Name: 'Dianum Ingot', Value: 3.75, Markup: 100},
    {Name: 'Durulium Ingot', Value: 2.4, Markup: 100},
    {Name: 'Erdorium Ingot', Value: 1.2, Markup: 100},
    {Name: 'Erionite Ingot', Value: 0.6, Markup: 100},
    {Name: 'Fairuz Ingot', Value: 0.15, Markup: 100},
    {Name: 'Folk Ingot', Value: 0.09, Markup: 100},
    {Name: 'Frakite Ingot', Value: 0.9, Markup: 100},
    {Name: 'Frigulite Ingot', Value: 0.36, Markup: 100},
    {Name: 'Galaurphite Ingot', Value: 14.7353, Markup: 100},
    {Name: 'Galvesperdite Ingot', Value: 6.3625, Markup: 100},
    {Name: 'Ganganite Ingot', Value: 0.36, Markup: 100},
    {Name: 'Gazzurdite Ingot', Value: 0.75, Markup: 100},
    {Name: 'Glam Ingot', Value: 0.12, Markup: 100},
    {Name: 'Gold Ingot', Value: 3, Markup: 100},
    {Name: 'Grunge Ingot', Value: 0.12, Markup: 100},
    {Name: 'Hansidian Ingot', Value: 0.03, Markup: 100},
    {Name: 'Hard Ingot', Value: 0.15, Markup: 100},
    {Name: 'Hebredite Ingot', Value: 0.75, Markup: 100},
    {Name: 'Himi Ingot', Value: 0.426, Markup: 100},
    {Name: 'Ignisium Ingot', Value: 2.1, Markup: 100},
    {Name: 'Iolite Ingot', Value: 0.6, Markup: 100},
    {Name: 'Iridium Ingot', Value: 0.15, Markup: 100},
    {Name: 'Iron Ingot', Value: 0.39, Markup: 100},
    {Name: 'Jazz Ingot', Value: 0.18, Markup: 100},
    {Name: 'Kaisenite Ingot', Value: 0.06, Markup: 100},
    {Name: 'Kanerium Ingot', Value: 7.5, Markup: 100},
    {Name: 'Kaz Ingot', Value: 0.12, Markup: 100},
    {Name: 'Kirtz Ingot', Value: 16.8, Markup: 100},
    {Name: 'Langotz Ingot', Value: 2.7, Markup: 100},
    {Name: 'Lanorium Ingot', Value: 0.66, Markup: 100},
    {Name: 'Leethiz Ingot', Value: 0.45, Markup: 100},
    {Name: 'Lidacon Ingot', Value: 4.5, Markup: 100},
    {Name: 'Lulu pearl', Value: 0.36, Markup: 100},
    {Name: 'Lysterium Ingot', Value: 0.03, Markup: 100},
    {Name: 'Lystionite Steel Ingot', Value: 4.0146, Markup: 100},
    {Name: 'Maganite Ingot', Value: 3.15, Markup: 100},
    {Name: 'Maladrite Ingot', Value: 0.12, Markup: 100},
    {Name: 'Maro Ingot', Value: 0.52, Markup: 100},
    {Name: 'Megan Ingot', Value: 0.54, Markup: 100},
    {Name: 'Morpheus Ingot', Value: 2.49, Markup: 100},
    {Name: 'Narcanisum Ingot', Value: 0.24, Markup: 100},
    {Name: 'Niksarium Ingot', Value: 1.95, Markup: 100},
    {Name: 'Nosi Ingot', Value: 0.24, Markup: 100},
    {Name: 'Olerin Ingot', Value: 0.21, Markup: 100},
    {Name: 'Ospra Ingot', Value: 0.06, Markup: 100},
    {Name: 'Petonium Ingot', Value: 5.37, Markup: 100},
    {Name: 'Platinum Ingot', Value: 9, Markup: 100},
    {Name: 'Praetonium Ingot', Value: 0.04, Markup: 100},
    {Name: 'Punk Ingot', Value: 0.21, Markup: 100},
    {Name: 'Pyrite Ingot', Value: 0.6, Markup: 100},
    {Name: 'Qasdeer Ingot', Value: 0.75, Markup: 100},
    {Name: 'Quantium Ingot', Value: 1.8, Markup: 100},
    {Name: 'Redulite Ingot', Value: 6.6, Markup: 100},
    {Name: 'Refined Sorensens Stone', Value: 0.09, Markup: 100},
    {Name: 'Reggea Ingot', Value: 0.24, Markup: 100},
    {Name: 'Rugaritz Ingot', Value: 4.5, Markup: 100},
    {Name: 'Solares Ingot', Value: 3.59, Markup: 100},
    {Name: 'Sothorite Ingot', Value: 0.27, Markup: 100},
    {Name: 'Sunburst Ingot', Value: 0.77, Markup: 100},
    {Name: 'Techno Ingot', Value: 0.3, Markup: 100},
    {Name: 'Telfium Ingot', Value: 0.42, Markup: 100},
    {Name: 'Terrudite Ingot', Value: 3.3, Markup: 100},
    {Name: 'Tridenite Ingot', Value: 6, Markup: 100},
    {Name: 'Valurite Ingot', Value: 18, Markup: 100},
    {Name: 'Veda Ingot', Value: 0.18, Markup: 100},
    {Name: 'Vesperdite Ingot', Value: 5.4, Markup: 100},
    {Name: 'Wenrex Ingot', Value: 0.51, Markup: 100},
    {Name: 'Wiley Ingot', Value: 0.9, Markup: 100},
    {Name: 'Xeremite Ingot', Value: 12, Markup: 100},
    {Name: 'Yashib Ingot', Value: 0.65, Markup: 100},
    {Name: 'Yulerium Ingot', Value: 1.5, Markup: 100},
    {Name: 'Zanderium Ingot', Value: 7.5, Markup: 100},
    {Name: 'Zinc Ingot', Value: 0.3, Markup: 100},
    {Name: 'Zircon Ingot', Value: 0.15, Markup: 100},
    {Name: 'Zorn Star Ingot', Value: 0.03, Markup: 100},
    {Name: 'Zulax Ingot', Value: 1.95, Markup: 100},
];

export const AllCraftingMaterials = [
    {Name: 'Oils & Residues', List: OilsAndResidues},
    {Name: 'Consumables', List: Consumables},
    {Name: 'Electronic Components', List: ElectronicComponents},
    {Name: 'Enhancer Components', List: EnhancerComponents},
    {Name: 'Fragments', List: Fragments},
    {Name: 'Generic Components', List: GenericComponents},
    {Name: 'Mechnical Components', List: MechnicalComponents},
    {Name: 'Metal Components', List: MetalComponents},
    {Name: 'Natural Materials', List: NaturalMaterials},
    {Name: 'Robot Components', List: RobotComponents},
    {Name: 'Vehicle Components', List: VehicleComponents},
    {Name: 'Refined Enmatter', List: RefinedEnmatter},
    {Name: 'Refined Ore', List: RefinedOre}
];