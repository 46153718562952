export const teleportsList = [
    { Name: 'Port Atlantis', Longitude: 61417, Latitude: 75218, Z: 143 },
    { Name: 'Port Atlantis Mall', Longitude: 61625, Latitude: 75186, Z: 165 },
    { Name: 'Camp Icarus', Longitude: 63224, Latitude: 74369, Z: 131 },
    { Name: 'Orthos West Mound', Longitude: 64094, Latitude: 77246, Z: 108 },
    { Name: 'Hestia', Longitude: 66941, Latitude: 76514, Z: 106 },
    { Name: 'Fort Lahar', Longitude: 66995, Latitude: 74876, Z: 201 },
    { Name: 'Chug\'s Hideout', Longitude: 68162, Latitude: 74758, Z: 140 },
    { Name: 'Notus', Longitude: 66756, Latitude: 70148, Z: 159 },
    { Name: 'Jurra Plateau', Longitude: 70691, Latitude: 71599, Z: 239 },
    { Name: 'Eos', Longitude: 71411, Latitude: 69464, Z: 336 },
    { Name: 'Zephyrus', Longitude: 69995, Latitude: 66925, Z: 150 },
    { Name: 'Athena Spaceport', Longitude: 71637, Latitude: 68193, Z: 790 },
    { Name: 'Boreas', Longitude: 74365, Latitude: 67342, Z: 141 },
    { Name: 'Isle of Troy', Longitude: 80295, Latitude: 66308, Z: 130 },
    { Name: 'Fort Troy', Longitude: 80507, Latitude: 68295, Z: 160 },
    { Name: 'Osere', Longitude: 81165, Latitude: 72661, Z: 144 },
    { Name: 'East Scylla Mountains', Longitude: 75051, Latitude: 72636, Z: 171 },
    { Name: 'Twin Peaks Mall', Longitude: 77933, Latitude: 76549, Z: 236 },
    { Name: 'Twin Peaks', Longitude: 77774, Latitude: 76664, Z: 163 },
    { Name: 'Tukar', Longitude: 85622, Latitude: 75750, Z: 212 },
    { Name: 'Ashi', Longitude: 82233, Latitude: 78322, Z: 148 },
    { Name: 'Nymphtown', Longitude: 87070, Latitude: 78473, Z: 105 },
    { Name: 'Fort Ares', Longitude: 80016, Latitude: 80240, Z: 157 },
    { Name: 'Jason Centre', Longitude: 75701, Latitude: 80234, Z: 117 },
    { Name: 'Chimera Canyons', Longitude: 78508, Latitude: 82336, Z: 125 },
    { Name: 'Minopolis', Longitude: 87472, Latitude: 85105, Z: 113 },
    { Name: 'Fort Pandora', Longitude: 84774, Latitude: 85237, Z: 121 },
    { Name: 'Aegis Mound', Longitude: 74231, Latitude: 85862, Z: 143 },
    { Name: 'Camp Echidna East Hangar', Longitude: 81002, Latitude: 87524, Z: 315 },
    { Name: 'Camp Echidna', Longitude: 79512, Latitude: 87863, Z: 216 },
    { Name: 'Camp Echidna North Hangar', Longitude: 77886, Latitude: 88976, Z: 141 },
    { Name: 'Camp Caravan', Longitude: 87149, Latitude: 89490, Z: 223 },
    { Name: 'Isslye', Longitude: 85383, Latitude: 91404, Z: 329 },
    { Name: 'Honuri', Longitude: 80747, Latitude: 91846, Z: 327 },
    { Name: 'Fort Argus', Longitude: 75569, Latitude: 92659, Z: 419 },
    { Name: 'Rogue Plains', Longitude: 78051, Latitude: 96372, Z: 127 },
    { Name: 'Rogue Plains West', Longitude: 78226, Latitude: 96964, Z: 166 },
    { Name: 'Rogue Plains East', Longitude: 79536, Latitude: 97414, Z: 135 },
    { Name: 'Fort Zeus', Longitude: 86533, Latitude: 94694, Z: 300 },
    { Name: 'North Space Base Docks B', Longitude: 88541, Latitude: 93888, Z: 315 },
    { Name: 'North Space Base Docks A', Longitude: 88605, Latitude: 94457, Z: 282 },
    { Name: 'North Space Base B', Longitude: 88580, Latitude: 95640, Z: 319 },
    { Name: 'North Space Base A', Longitude: 88124, Latitude: 96678, Z: 132 },
    { Name: 'Limnadian District', Longitude: 71236, Latitude: 79123, Z: 120 },
    { Name: 'Atlas Haven', Longitude: 71669, Latitude: 82344, Z: 132 },
    { Name: 'Southern Ithaca', Longitude: 70851, Latitude: 85747, Z: 155 },
    { Name: 'Fort Ithaca', Longitude: 68554, Latitude: 87950, Z: 139 },
    { Name: 'Fort Medusa', Longitude: 68893, Latitude: 84386, Z: 140 },
    { Name: 'Fort Fury', Longitude: 68244, Latitude: 79030, Z: 362 },
    { Name: 'Zychion Citadel', Longitude: 69546, Latitude: 77431, Z: 143 },
    { Name: 'Billy\'s Spaceship Afterworld', Longitude: 62010, Latitude: 80262, Z: 153 },
    { Name: 'Camp Phoenix', Longitude: 62493, Latitude: 82576, Z: 369 },
    { Name: 'The MegaVolt', Longitude: 59083, Latitude: 84462, Z: 224 },
    { Name: 'Corinth West Hangars A', Longitude: 60806, Latitude: 86646, Z: 125 },
    { Name: 'Corinth West Hangars B', Longitude: 60968, Latitude: 86285, Z: 132 },
    { Name: 'Cape Corinth', Longitude: 61748, Latitude: 87921, Z: 237 },
    { Name: 'Fort Sisyphus', Longitude: 62150, Latitude: 85399, Z: 325 },
    { Name: 'Half Moon Cove', Longitude: 63504, Latitude: 89180, Z: 105 },
    { Name: 'Half Moon Bay', Longitude: 63373, Latitude: 87516, Z: 123 },
    { Name: 'Corinth East Hangars A', Longitude: 64816, Latitude: 86060, Z: 317 },
    { Name: 'Corinth East Hangars B', Longitude: 64995, Latitude: 85826, Z: 317 },
    { Name: 'Livas Heights', Longitude: 66451, Latitude: 85342, Z: 193 },
    { Name: 'Atlantis Archipelago', Longitude: 61500, Latitude: 68900, Z: 108 },
    { Name: 'Hogglo Ranch', Longitude: 18051, Latitude: 59341, Z: 123 },
    { Name: 'Oshiri Hearts', Longitude: 21811, Latitude: 61331, Z: 148 },
    { Name: 'New Switzerland', Longitude: 26330, Latitude: 60833, Z: 112 },
    { Name: 'BIG Industries - OLA42', Longitude: 29073, Latitude: 58730, Z: 108 },
    { Name: 'Z\'s Little Farm', Longitude: 29162, Latitude: 56009, Z: 217 },
    { Name: 'Shinook Jungle', Longitude: 27807, Latitude: 50917, Z: 184 },
    { Name: 'Emerald Lakes Mall', Longitude: 31583, Latitude: 50208, Z: 154 },
    { Name: 'Shinook River', Longitude: 27958, Latitude: 45423, Z: 210 },
    { Name: 'Solfais Crater', Longitude: 21308, Latitude: 53848, Z: 105 },
    { Name: 'That Freaking Cold Place', Longitude: 18769, Latitude: 51540, Z: 150 },
    { Name: 'BIG Industries - Saba Camp', Longitude: 18022, Latitude: 46659, Z: 113 },
    { Name: 'Winter Park', Longitude: 18508, Latitude: 45726, Z: 122 },
    { Name: 'Segna Forest', Longitude: 18518, Latitude: 44420, Z: 176 },
    { Name: 'Angelica\'s Paradise', Longitude: 22135, Latitude: 46873, Z: 153 },
    { Name: 'Chilled Hogglo and Maffoid', Longitude: 23033, Latitude: 44191, Z: 109 },
    { Name: 'Ahh...the Atrax', Longitude: 25918, Latitude: 46800, Z: 138 },
    { Name: 'Cayuze', Longitude: 20177, Latitude: 39720, Z: 109 },
    { Name: 'Oyster Isle', Longitude: 17676, Latitude: 35371, Z: 169 },
    { Name: 'Fort Cayuze', Longitude: 19793, Latitude: 36409, Z: 104 },
    { Name: 'Myrene Island', Longitude: 19303, Latitude: 27060, Z: 104 },
    { Name: 'Akmuul Island', Longitude: 25129, Latitude: 27321, Z: 204 },
    { Name: 'Memorial Island', Longitude: 22656, Latitude: 32022, Z: 125 },
    { Name: 'Omegaton West Habitat', Longitude: 25657, Latitude: 37645, Z: 138 },
    { Name: 'Nea\'s Place', Longitude: 29355, Latitude: 35521, Z: 259 },
    { Name: 'Amethera Outback Land #15', Longitude: 30999, Latitude: 30013, Z: 200 },
    { Name: 'OLA 17 The Salty Flats', Longitude: 31549, Latitude: 35751, Z: 181 },
    { Name: '~Royal Club~', Longitude: 30849, Latitude: 40280, Z: 143 },
    { Name: 'Lorfat\u{00b4}s Prize', Longitude: 31278, Latitude: 43607, Z: 337 },
    { Name: 'Silver Ridge', Longitude: 32999, Latitude: 37891, Z: 562 },
    { Name: 'BIG Industries - OLA01', Longitude: 34700, Latitude: 36301, Z: 199 },
    { Name: 'Nate Valley', Longitude: 36685, Latitude: 33306, Z: 312 },
    { Name: 'Tetania\'s-by-the-sea!', Longitude: 35223, Latitude: 26861, Z: 111 },
    { Name: 'Rei\'s Defence', Longitude: 37109, Latitude: 29198, Z: 118 },
    { Name: 'Sakura City', Longitude: 39666, Latitude: 30227, Z: 102 },
    { Name: 'Club Sweat', Longitude: 37629, Latitude: 39350, Z: 130 },
    { Name: 'Zonnestraaltje', Longitude: 36246, Latitude: 44080, Z: 165 },
    { Name: 'Wolverine Hope', Longitude: 37844, Latitude: 46787, Z: 151 },
    { Name: 'New Oxford', Longitude: 33731, Latitude: 47703, Z: 119 },
    { Name: 'Genesis Amthera Headquarters', Longitude: 34254, Latitude: 48209, Z: 110 },
    { Name: 'Treasure Island City', Longitude: 35150, Latitude: 49848, Z: 149 },
    { Name: 'New Treasure Island Main Hangar', Longitude: 37055, Latitude: 53587, Z: 179 },
    { Name: 'New Treasure Island North Hangar A', Longitude: 39347, Latitude: 54828, Z: 235 },
    { Name: 'New Treasure Island North Hangar B', Longitude: 39811, Latitude: 55229, Z: 228 },
    { Name: 'New Treasure Island Northern Shores', Longitude: 40272, Latitude: 55487, Z: 101 },
    { Name: 'Jameson\'s Reach', Longitude: 35391, Latitude: 60116, Z: 251 },
    { Name: 'Naval Station Triton', Longitude: 40417, Latitude: 58725, Z: 132 },
    { Name: 'Pemphredo Island Teleporter', Longitude: 50907, Latitude: 68496, Z: 225 },
    { Name: 'Medusa Teleporter', Longitude: 53409, Latitude: 69338, Z: 105 },
    { Name: 'Ouroboros Island Teleporter', Longitude: 55589, Latitude: 72142, Z: 158 },
    { Name: 'Fort Victoria', Longitude: 55206, Latitude: 60155, Z: 174 },
    { Name: 'Odysseus Landing', Longitude: 54287, Latitude: 64903, Z: 112 },
    { Name: 'Bilton Towers', Longitude: 33470, Latitude: 47352, Z: 105 }
];