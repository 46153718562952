import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Footer } from '../components/common/footer';
import { DailyMissions } from '../components/projects/DailyMissions/DailyMissions';
import { AuctionCalc } from '../components/projects/AuctionCalculator/AuctionCalc';
import { EntropiaMapNew } from '../components/projects/EntropiaMap/EntropiaMapNew';
import { Tools } from '../models/ToolsEnum';
import { TeleportFinder } from '../components/projects/TeleportFinder/TeleportFinder';
import { SlotMachine } from '../components/projects/SlotMachine/SlotMachine';
import { TaxiService } from '../components/projects/TaxiService/TaxiService';
// import { FF14Weeklies } from '../components/projects/FF14Weeklies/FF14Weeklies';

const Main = styled.main({
    display: 'block',
    position: 'relative',
    minWidth: '100%',
    minHeight: '100%',
    height: '100%',
    paddingTop: '0',
});

const CardContainer = styled.div({
    position: 'relative',
    display: 'block',
    margin: '0 auto',
    backgroundColor: 'black',
    boxSizing: 'border-box',
    padding: '0',

    '&#tools': {
        backgroundColor: 'rgba(20,20,20,1)',
        minHeight: '500px'
    },

    '&#media': {
        display: 'flex',
        flexDirection: 'row',
    }
});

const ToolList = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderBottom: '1px solid white',
    maxWidth: '1000px',
    margin: '0 auto',

    'div': {
        padding: '20px',
        fontSize: 20,
        textDecoration: 'none',
        color: 'white',

        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
})

// const AdSpaceHorizontal = styled.div({
//     display: 'block',
//     maxWidth: 'fit-content',
//     minHeight: '50px',
//     border: '3px dashed white',
//     width: '100%',
//     margin: '0 auto',
//     color: 'white',
//     padding: '20px',

//     'h2': {
//         marginTop: 0
//     }
// })

export const Homepage: React.FC = () => {
    const [currTool, setCurrTool] = useState(Tools.DailyMission);

    const displayToolContent = () => {
        switch (currTool) {
            case Tools.DailyMission:
                return <DailyMissions />
            case Tools.Tools:
                return <AuctionCalc />
            case Tools.EntropiaMapNew:
                return <EntropiaMapNew />
            case Tools.TaxiService:
                return <TaxiService />
            case Tools.RandomFun:
                return <SlotMachine />
            default:
                return <DailyMissions />
        }
    }

    return <Main>
        {/* <AdSpaceHorizontal>
            <h2>WANT TO ADVERTISE? CONTACT ME IN-GAME</h2>
            <span>PONTUS RIPCRAZE VIRUS</span>
        </AdSpaceHorizontal> */}
        {currTool === Tools.DailyMission && <TeleportFinder />}
        {/* <p style={{color: 'white', maxWidth: '700px', margin: '1em auto', backgroundColor: 'rgba(20,20,20,0.7)', padding: '1em'}}>
            To keep this website ad-free and up running you can help either by donating through PayPal <a style={{color: 'lightblue'}} target='_blank' rel='noopener noreferrer' href='https://www.paypal.com/paypalme/Ripcraze'>HERE</a> or by making a donation in-game to 'Pontus Ripcraze Virus'. 
            <br/> Thank you so much for using the website and supporting me in my work for this amazing game.
        </p> */}

        {/* CHAT GPT FF14 WEEKLIES LIST */}
        {/* <div style={{backgroundColor: 'rgba(20,20,20,1)', color: 'white', width: 'fit-content', padding: '0', margin: '0 auto 1em auto', border: '2px solid white', borderRadius: '12px'}}>
            <FF14Weeklies />
        </div> */}

        {/* <div style={{ backgroundColor: 'rgba(20,20,20,1)', color: 'white', width: 'fit-content', padding: '1em', margin: '0 auto 1em auto', border: '2px solid white', borderRadius: '12px' }}>
            <SlotMachine />
        </div> */}

        <CardContainer id="tools">
            <ToolList>
                <div onClick={() => setCurrTool(Tools.DailyMission)}>
                    DAILY MISSION TRACKER
                </div>
                <div onClick={() => setCurrTool(Tools.EntropiaMapNew)}>
                    ENTROPIA MAP (WIP)
                </div>
                {/* <div onClick={() => setCurrTool(Tools.TaxiService)}>
                    TAXI SERVICE (Demo)
                </div> */}
                {/* <div onClick={() => setCurrTool(Tools.TaxiService)}>
                    TAXI SERVICE (WIP)
                </div>
                <div onClick={() => setCurrTool(Tools.Tools)}>
                    CALCULATION TOOLS
                </div> */}
                {/* <div onClick={() => setCurrTool(Tools.RandomFun)}>
                    RANDOM FUN
                </div> */}
            </ToolList>
            <div>
                {displayToolContent()}
            </div>
        </CardContainer>
        <Footer />
    </Main>
}