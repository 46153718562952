import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import fullMap from './map_image/maps/calypso_map_updated_high_quality.jpg';
import { Dropdown } from '../../inputs/Dropdown';
import { generateCreatureList } from './data';
import { mobSpawnsList } from './data/mobSpawnsList';
import { teleportsList } from './data/teleportsList';
import { outpostsList } from './data/outpostsList';
import { pvpZonesList } from './data/pvpZonesList';
import {
  getMapCoordsXFromEUCoords,
  getMapCoordsYFromEUCoords,
  getMapPointsFromEUCoords,
} from './EntropiaMapHelpers';
import tpIcon from './map_image/teleport_icon.png';
// import { generatePlanetsList } from './data/planetsList';
import { MultiSelect } from '../../inputs/MultiSelect';
import { landAreasList, landAreaDetails, generateLandAreaList } from './data/landAreasList';
import { ChangeLogs } from './data/changelog';

//const mobLink = 'http://www.entropiawiki.com/Info.aspx?chart=Mob&id=';

const Main = styled.div({
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  backgroundColor: 'rgba(20,20,20,1)',
  color: 'white',
});

const OptionsContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  width: '100%',
  maxWidth: '768px',
  height: 'auto',
  margin: '0 auto',
  maxHeight: '74px',
});

const Dropdowns = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '100%',

  'select': {
    maxWidth: '150px'
  }
});

const MapContainer = styled.svg({
  position: 'relative',
  width: '100%',
  height: 'auto',
  marginTop: '1em',
  maxWidth: 768,
  maxHeight: 768,
  minWidth: 768, // TODO: Figure out scaling the pos when this is set lower
  minHeight: 768,
  touchAction: 'auto',
  border: '1px solid white'
});

const MapDetailsContainer = styled.div({
  position: 'relative',
  width: '200px',
  height: '768px',
  marginTop: '1em',
  border: '1px solid white',
  overflowY: 'scroll',
  borderRadius: '0 5px 5px 0'
});

const DetailSpawnItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  width: '100%',
  height: 'fit-content',
  borderBottom: '1px solid white',
  padding: '7px',
  boxSizing: 'border-box'
});

const DetailSpawnItemRow = styled.div({
  display: 'flex',
  flexDirection: 'column',

  'div': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  '.spawnItemRowButtons': {
    display: 'flex',
    marginTop: '0.5rem',

    'button': {
      border: '1px solid white',
      padding: 0
    }
  }
});

const MapSpawnDetailsHeader = styled.div({
  height: 'auto',
  borderBottom: '1px solid white',
  padding: '7px',
  display: 'flex'
});

const SpawnDetailsDensityBar = styled.div({
  width: '100%',
  height: '8px',
  backgroundColor: '#e0e0e0',
  borderRadius: '15px',
  overflow: 'hidden',
  position: 'relative',
  margin: 'auto 0 auto 2em'
});

const SpawnDetailsDensityBarFill = styled.div({
  height: '100%',
  background: 'linear-gradient(to right, green 0%, yellow 50%, red 100%)',
  borderRadius: '15px 0 0 15px',
  width: '100%'
});

const SpawnDetailsDensityBarPercentage = styled.div({
  height: '100%',
  background: '#7d7367',
  borderRadius: '0',
  width: 0,
  right: 0,
  position: 'absolute'
});

const TeleportGroup = styled.g({
  'text': {
    pointerEvents: 'none',
    display: 'none',
    fill: 'white',
  },

  ':hover text': {
    display: 'block',
  }
});

const OutpostGroup = styled.g({
  'text': {
    pointerEvents: 'none',
    display: 'none',
    fill: 'white',
  },

  ':hover text': {
    display: 'block',
  }
});

const PvpGroup = styled.g<{ isDeadly: boolean }>(({ isDeadly }) => ({
  'polygon': {
    fill: isDeadly ? '#d40400' : '#b88700',
    opacity: 0.4,
  },

  'text': {
    pointerEvents: 'none',
    display: 'none',
    fill: 'white',
  },

  ':hover text': {
    display: 'block',
  }
}));

const CreatureSpawnText = styled.text({
  zIndex: 9999,
  pointerEvents: 'none',
  display: 'none',
  fill: 'white',
  position: 'absolute'
});

const Label = styled.label<{ disabled?: boolean }>(({ disabled }) => ({
  fontSize: 16,
  marginLeft: 15,
  opacity: disabled ? 0.5 : 1,
}));

const PlanetDropdown = styled(Dropdown)({
  maxWidth: '50%',
});

const LandAreaDropdown = styled(Dropdown)({
  maxWidth: '50%',
});

const CreatureDropdown = styled(Dropdown)({
  maxWidth: '50%',
});

const MaturityDropdown = styled(Dropdown)({
  maxWidth: '50%',
});

const LandArea = styled.polygon({
  ':hover': {
    cursor: 'pointer'
  }
});

const LandAreaDetailsOverlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10000
});

const LandAreaDetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 500,
  minHeight: 500,
  height: 'fit-content',
  background: 'rgba(20,20,20,1)',
  border: '1px solid white',
  borderRadius: '15px',
  paddingBottom: '20px'
});

const LandAreaDetailsWaypoint = styled.span({
  ':hover': {
    cursor: 'pointer'
  }
});

const ChangelogsOverlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10000
});

const ChangelogsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 500,
  minHeight: 500,
  height: 'fit-content',
  background: 'rgba(20,20,20,1)',
  border: '1px solid white',
  borderRadius: '15px',
  paddingBottom: '20px'
});

const ChangeLogContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'normal',
  alignSelf: 'center',
  font: 'small-caption',

  ul: {
    padding: 0,
    marginTop: 0,

    li: {
      listStyle: 'circle',
      textAlign: 'left'
    }
  }
});

export const EntropiaMapNew: React.FC = () => {
  const svgNS = "http://www.w3.org/2000/svg";
  const isTesting = false;

  const maxViewBoxSize = 768;
  const minZoom = 1;
  const maxZoom = isTesting ? 10 : 8;
  const pixelToEuX = 95.79;
  const pixelToEuY = -96;
  const minEuX = 16480;
  const minEuY = 98304;
  const cellSize = 85.33333;
  const gridSize = 9;
  //const maxEuX = 90048;
  //const maxEuY = 24576;
  const midOffsetX = 50;
  const midOffsetY = 45;

  const testXOffset = 0;
  const testYOffset = 300;

  const [currCreature, setCurrCreature] = useState(isTesting ? 'all' : 'none');
  const [currCreatureWikiId, setCurrCreatureWikiId] = useState(0);
  // const [, setCurrPlanet] = useState('Planet Calypso');
  const [currMaturity, setCurrMaturity] = useState('all');
  const [showTeleports, setShowTeleports] = useState(true);
  const [showOutposts, setShowOutposts] = useState(true);
  const [showLandAreas, setShowLandAreas] = useState(true);
  const [showGridLines, setShowGridLines] = useState(isTesting ? true : false);
  const [showPvp, setShowPvp] = useState(true);
  const [zoom, setZoom] = useState<number>(isTesting ? 4 : 1); // 1 default, use 9 when placing stuff
  const [, setCurrZoomFactor] = useState(isTesting ? 1.0666666666666667 : 1);
  const [viewBoxXOffset, setViewBoxXOffset] = useState<number>(isTesting ? testXOffset : 0); // 0 default
  const [viewBoxYOffset, setViewBoxYOffset] = useState<number>(isTesting ? testYOffset : 0); // 0 default
  const [viewBoxWidth, setViewBoxWidth] = useState<number>(maxViewBoxSize);
  const [viewBoxHeight, setViewBoxHeight] = useState<number>(maxViewBoxSize);
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [lastMousePos, setLastMousePos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [currentEUPos, setCurrentEUPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [mappingAreaPoints, setMappingAreaPoints] = useState("");
  const [isWaypointCopied, setIsWaypointCopied] = useState(false);
  const [, setWaypointIndicatorPos] = useState({ x: -10000, y: -10000 });
  // const [selectedCreaturesData, setSelectedCreaturesData] = useState({});
  const [showLandAreaDetailsOverlay, setShowLandAreasDetailsOverlay] = useState(false);
  const [selectedLandArea, setSelectedLandArea] = useState({} as landAreaDetails);
  const [currLandArea, setCurrLandArea] = useState('None');
  const [showChangeLogsOverlay, setShowChangeLogsOverlay] = useState(false);

  const generateCreatureSpawns = (name: string, maturity: string) => {
    setWaypointIndicatorPos({ x: -10000, y: -10000 });
    if (!mouseDown) {
      if (name !== 'all') {
        var specifiedCreatures = mobSpawnsList.filter(x => x.Name === name);

        if (maturity !== 'all') {
          return specifiedCreatures.map(c =>
            c.spawns.filter(x => x.Maturity === maturity).map(z =>
              <polygon points={getMapPointsFromEUCoords(z.Pos)} id={c.id + z.Pos.split(' ')[0]} key={c.id + z.Pos.split(' ')[0]} style={{
                zIndex: 1, fill: z.Maturity === 'small' ? '#009900' : z.Maturity === 'mid' ? '#b88700' : '#d40400',
                opacity: 0.8
              }} onMouseOver={(e) => {
                var spawnId = e.currentTarget.getAttribute("id") + "text";
                var textelem = document.getElementById(spawnId);
                if (textelem !== null) {
                  textelem.style.display = "block";
                  textelem.style.left = `${e.pageX}px`;
                  textelem.style.top = `${e.pageY}px`
                }
              }} onMouseOut={(e) => {
                var spawnId = e.currentTarget.getAttribute("id") + "text";
                var textelem = document.getElementById(spawnId);
                if (textelem !== null) {
                  textelem.style.display = "none";
                }
              }} />
            )
          )
        } else {
          return specifiedCreatures.map(x => x.spawns.map(z =>
            <polygon points={getMapPointsFromEUCoords(z.Pos)} id={x.id + z.Pos.split(' ')[0]} key={x.id + z.Pos.split(' ')[0]} style={{
              zIndex: 1, fill: z.Maturity === 'small' ? '#009900' : z.Maturity === 'mid' ? '#b88700' : '#d40400',
              opacity: 0.8
            }} onMouseOver={(e) => {
              var spawnId = e.currentTarget.getAttribute("id") + "text";
              var textelem = document.getElementById(spawnId);
              if (textelem !== null) {
                textelem.style.display = "block";
                textelem.style.left = `${e.pageX}px`;
                textelem.style.top = `${e.pageY}px`
              }
            }} onMouseOut={(e) => {
              var spawnId = e.currentTarget.getAttribute("id") + "text";
              var textelem = document.getElementById(spawnId);
              if (textelem !== null) {
                textelem.style.display = "none";
              }
            }} />
          )
          )
        }
      } else if (name === 'all') {
        var allSpawns = mobSpawnsList;

        if (maturity !== 'all') {
          return allSpawns.map(c =>
            c.spawns.filter(x => x.Maturity === maturity).map(z =>
              <polygon points={getMapPointsFromEUCoords(z.Pos)} id={c.id + z.Pos.split(' ')[0]} key={c.id + z.Pos.split(' ')[0]} style={{
                zIndex: 1, fill: z.Maturity === 'small' ? '#009900' : z.Maturity === 'mid' ? '#b88700' : '#d40400',
                opacity: 0.8
              }} onMouseOver={(e) => {
                var spawnId = e.currentTarget.getAttribute("id") + "text";
                var textelem = document.getElementById(spawnId);
                if (textelem !== null) {
                  textelem.style.display = "block";
                  textelem.style.left = `${e.pageX}px`;
                  textelem.style.top = `${e.pageY}px`
                }
              }} onMouseOut={(e) => {
                var spawnId = e.currentTarget.getAttribute("id") + "text";
                var textelem = document.getElementById(spawnId);
                if (textelem !== null) {
                  textelem.style.display = "none";
                }
              }} />
            )
          )
        } else {
          return allSpawns.map(c =>
            c.spawns.map(z =>
              <polygon points={getMapPointsFromEUCoords(z.Pos)} id={c.id + z.Pos.split(' ')[0]} key={c.id + z.Pos.split(' ')[0]} style={{
                zIndex: 1, fill: z.Maturity === 'small' ? '#009900' : z.Maturity === 'mid' ? '#b88700' : '#d40400',
                opacity: 0.8
              }} onMouseOver={(e) => {
                var spawnId = e.currentTarget.getAttribute("id") + "text";
                var textelem = document.getElementById(spawnId);
                if (textelem !== null) {
                  textelem.style.display = "block";
                  textelem.style.left = `${e.pageX}px`;
                  textelem.style.top = `${e.pageY}px`
                }
              }} onMouseOut={(e) => {
                var spawnId = e.currentTarget.getAttribute("id") + "text";
                var textelem = document.getElementById(spawnId);
                if (textelem !== null) {
                  textelem.style.display = "none";
                }
              }} />
            )
          )
        }
      }
    }
  }

  const generateCreatureSpawnsText = (name: string, maturity: string) => {
    if (!mouseDown) {
      if (name !== 'all') {
        var specifiedCreatures = mobSpawnsList.filter(x => x.Name === name);

        if (maturity !== 'all') {
          return specifiedCreatures.map(c =>
            c.spawns.filter(x => x.Maturity === maturity).map(z =>
              <CreatureSpawnText style={{ fontSize: `${14 / (zoom * 0.9)}px`, zIndex: 9999 }} id={c.id + z.Pos.split(' ')[0] + "text"} key={c.id + z.Pos.split(' ')[0] + "text"}
                x={getMapCoordsXFromEUCoords(findMiddlePoint(z.Pos).x as number) - ((c.Name.length + z.Min.length + z.Max.length) / zoom)}
                y={getMapCoordsYFromEUCoords(findMiddlePoint(z.Pos).y as number) - 2}>
                {`${c.Name} [${z.Min}${z.Max.length > 0 ? `${' - ' + z.Max}` : ''}]`}
                {`${z.Mix.length > 0 ? `${' (Mix: ' + z.Mix + ')'}` : ''}`}
                {isTesting && `${' - Id: ' + z.Id}`}
              </CreatureSpawnText>
            )
          )
        } else {
          return specifiedCreatures.map(c => c.spawns.map(z =>
            <CreatureSpawnText style={{ fontSize: `${14 / (zoom * 0.9)}px`, zIndex: 9999 }} id={c.id + z.Pos.split(' ')[0] + "text"} key={c.id + z.Pos.split(' ')[0] + "text"}
              x={getMapCoordsXFromEUCoords(findMiddlePoint(z.Pos).x as number) - ((c.Name.length + z.Min.length + z.Max.length) / zoom)}
              y={getMapCoordsYFromEUCoords(findMiddlePoint(z.Pos).y as number) - 2}>
              {`${c.Name} [${z.Min}${z.Max.length > 0 ? `${' - ' + z.Max}` : ''}]`}
              {`${z.Mix.length > 0 ? `${' (Mix: ' + z.Mix + ')'}` : ''}`}
              {isTesting && `${' - Id: ' + z.Id}`}
            </CreatureSpawnText>
          )
          )
        }
      } else if (name === 'all') {
        var allSpawns = mobSpawnsList;

        if (maturity !== 'all') {
          return allSpawns.map(c =>
            c.spawns.filter(x => x.Maturity === maturity).map(z =>
              <CreatureSpawnText style={{ fontSize: `${14 / (zoom * 0.9)}px`, zIndex: 9999 }} id={c.id + z.Pos.split(' ')[0] + "text"} key={c.id + z.Pos.split(' ')[0] + "text"}
                x={getMapCoordsXFromEUCoords(findMiddlePoint(z.Pos).x as number) - ((c.Name.length + z.Min.length + z.Max.length) / zoom)}
                y={getMapCoordsYFromEUCoords(findMiddlePoint(z.Pos).y as number) - 2}>
                {`${c.Name} [${z.Min}${z.Max.length > 0 ? `${' - ' + z.Max}` : ''}]`}
                {`${z.Mix.length > 0 ? `${' (Mix: ' + z.Mix + ')'}` : ''}`}
                {isTesting && `${' - Id: ' + z.Id}`}
              </CreatureSpawnText>
            )
          )
        } else {
          return allSpawns.map(c =>
            c.spawns.map(z =>
              <CreatureSpawnText style={{ fontSize: `${14 / (zoom * 0.9)}px`, zIndex: 9999 }} id={c.id + z.Pos.split(' ')[0] + "text"} key={c.id + z.Pos.split(' ')[0] + "text"}
                x={getMapCoordsXFromEUCoords(findMiddlePoint(z.Pos).x as number) - ((c.Name.length + z.Min.length + z.Max.length) / zoom)}
                y={getMapCoordsYFromEUCoords(findMiddlePoint(z.Pos).y as number) - 2}>
                {`${c.Name} [${z.Min}${z.Max.length > 0 ? `${' - ' + z.Max}` : ''}]`}
                {`${z.Mix.length > 0 ? `${' (Mix: ' + z.Mix + ')'}` : ''}`}
                {isTesting && `${' - Id: ' + z.Id}`}
              </CreatureSpawnText>
            )
          )
        }
      }
    }
  }

  const createLine = (x1: string, y1: string, x2: string, y2: string) => {
    var svg = document.getElementById("map");
    if (svg !== null) {
      const line = document.createElementNS(svgNS, "line");
      line.setAttribute("x1", x1);
      line.setAttribute("y1", y1);
      line.setAttribute("x2", x2);
      line.setAttribute("y2", y2);
      line.setAttribute("stroke", "black");  // Line color
      line.setAttribute("stroke-width", "0.25");
      line.classList.add("grid-line");
      svg.appendChild(line);
    }
  }

  const generateGrid = () => {
    if (showGridLines) {
      const lines = document.querySelectorAll('.grid-line');
      if (lines.length <= 0) {
        for (let i = 0; i <= gridSize; i++) {
          let pos = i * cellSize;
          createLine(pos.toString(), "0", pos.toString(), maxViewBoxSize.toString());
        }

        // Draw horizontal lines
        for (let i = 0; i <= gridSize; i++) {
          let pos = i * cellSize;
          createLine("0", pos.toString(), maxViewBoxSize.toString(), pos.toString());
        }
      }
    } else {
      const lines = document.querySelectorAll('.grid-line');
      lines.forEach(line => {
        return line.remove();
      });  // Remove each line from the SVG
    }
  }

  const findMiddlePoint = (spawnOutlines: string) => {
    var coords = getCoordsFromSpawnString(spawnOutlines);

    let totalX = 0;
    let totalY = 0;

    // Calculate sum of x and y coordinates
    for (let point of coords.xPositions) {
      totalX += point;
    }

    for (let point of coords.yPositions) {
      totalY += point;
    }

    // Calculate average x and y coordinates
    const avgX = totalX / coords.xPositions.length;
    const avgY = totalY / coords.yPositions.length;
    if (isNaN(avgY)) {
      console.log(coords);
    }

    return { x: avgX, y: avgY };
  }

  const getCoordsFromSpawnString = (spawn: string) => {
    const coordinates = spawn.split(" ");

    // Initialize arrays to store x and y positions
    const xPositions: number[] = [];
    const yPositions: number[] = [];

    // Iterate over each coordinate and extract x and y positions
    coordinates.forEach(coordinate => {
      // Split the coordinate by comma to get x and y values
      const [x, y] = coordinate.split(",");

      // Convert x and y values to numbers and push them to respective arrays
      xPositions.push(parseFloat(x));
      yPositions.push(parseFloat(y));
    });

    return { xPositions, yPositions };
  }

  const navigateToMidPosition = (borderPositions: string) => {
    var midPoints = findMiddlePoint(borderPositions);
    setZoom(maxZoom);
    var vbX = getMapCoordsXFromEUCoords(midPoints.x) - midOffsetX;
    var vbY = getMapCoordsYFromEUCoords(midPoints.y) - midOffsetY;
    setViewBoxXOffset(vbX);
    setViewBoxYOffset(vbY);
    setWaypointIndicatorPos({ x: vbX, y: vbY });
  }

  const displaySpawnDetails = (name: string, maturity: string) => {
    if (name !== 'all') {
      var specifiedCreatures = mobSpawnsList.filter(x => x.Name === name);

      if (specifiedCreatures.length > 0) {
        setCurrCreatureWikiId(specifiedCreatures[0].id as unknown as number);
      }

      if (maturity !== 'all') {
        return specifiedCreatures.map(x =>
          x.spawns.filter(y => y.Maturity === maturity).sort((a, b) => b.DensitySize - a.DensitySize).map(mob =>
            <DetailSpawnItem key={x.id + mob.Pos.split(' ')[0]}>
              <DetailSpawnItemRow>
                <div><span>Maturity:</span><span style={{ textAlign: 'right' }}>{mob.Min}{mob.Max.length > 0 ? `${' - ' + mob.Max}` : ''}</span></div>
                <div><span>Density:</span>
                  <SpawnDetailsDensityBar>
                    <SpawnDetailsDensityBarFill />
                    <SpawnDetailsDensityBarPercentage style={{ width: `${100 - mob.DensitySize}%` }} />
                  </SpawnDetailsDensityBar>
                </div>
                {mob.Notes.length > 0 && <div><span>Notes:</span><span style={{ textAlign: 'right', color: 'rgb(251 111 111)', margin: 'auto 0', font: 'small-caption' }}>{mob.Notes}</span></div>}
                {mob.Mix.length > 0 && <div><span>Mix:</span><span style={{ textAlign: 'right', color: 'rgb(233 162 109)', margin: 'auto 0', font: 'small-caption' }}>{mob.Mix}</span></div>}
                <div className='spawnItemRowButtons'>
                  <button onClick={() => {
                    navigateToMidPosition(mob.Pos);
                  }}>Show on map</button>
                  <button onClick={() => {
                    var midPoints = findMiddlePoint(mob.Pos);
                    var waypoint = `/wp [Calypso, ${midPoints.x.toFixed(0)}, ${midPoints.y.toFixed(0)}, ${150}, ${x.Name}]`;
                    navigator.clipboard.writeText(waypoint);
                    setIsWaypointCopied(true);
                  }}>Get ingame Waypoint</button>
                </div>
              </DetailSpawnItemRow>
            </DetailSpawnItem >
          )
        )
      } else {
        return specifiedCreatures.map(x =>
          x.spawns.sort((a, b) => b.DensitySize - a.DensitySize).map(mob =>
            <DetailSpawnItem key={x.id + mob.Pos.split(' ')[0]}>
              <DetailSpawnItemRow>
                <div><span>Maturity:</span><span style={{ textAlign: 'right' }}>{mob.Min}{mob.Max.length > 0 ? `${' - ' + mob.Max}` : ''}</span></div>
                <div><span>Density:</span>
                  <SpawnDetailsDensityBar>
                    <SpawnDetailsDensityBarFill />
                    <SpawnDetailsDensityBarPercentage style={{ width: `${100 - mob.DensitySize}%` }} />
                  </SpawnDetailsDensityBar>
                </div>
                {mob.Notes.length > 0 && <div><span>Notes:</span><span style={{ textAlign: 'right', color: 'rgb(251 111 111)', margin: 'auto 0', font: 'small-caption' }}>{mob.Notes}</span></div>}
                {mob.Mix.length > 0 && <div><span>Mix:</span><span style={{ textAlign: 'right', color: 'rgb(233 162 109)', margin: 'auto 0', font: 'small-caption' }}>{mob.Mix}</span></div>}
                <div className='spawnItemRowButtons'>
                  <button onClick={() => {
                    var midPoints = findMiddlePoint(mob.Pos);
                    setZoom(maxZoom);
                    var vbX = getMapCoordsXFromEUCoords(midPoints.x) - midOffsetX;
                    var vbY = getMapCoordsYFromEUCoords(midPoints.y) - midOffsetY;
                    setViewBoxXOffset(vbX);
                    setViewBoxYOffset(vbY);
                    setWaypointIndicatorPos({ x: vbX, y: vbY });
                  }}>Show on map</button>
                  <button onClick={() => {
                    var midPoints = findMiddlePoint(mob.Pos);
                    var waypoint = `/wp [Calypso, ${midPoints.x.toFixed(0)}, ${midPoints.y.toFixed(0)}, ${150}, ${x.Name + " " + mob.Min + " " + mob.Max}]`;
                    navigator.clipboard.writeText(waypoint.trimEnd());
                    setIsWaypointCopied(true);
                  }}>Get ingame Waypoint</button>
                </div>
              </DetailSpawnItemRow>
            </DetailSpawnItem>
          )
        )
      }
    }
  }

  const buildChangeLogs = () => {
    return (<ChangeLogContainer>
      {ChangeLogs.map(x => {
        return (<div key={x.date}>
          <p>{x.date}</p>
          <ul>
            {x.changes.map(y => {
              return <li key={y}>{y}</li>
            })}
          </ul>
        </div>)
      })}
    </ChangeLogContainer>)
  }

  // ------------------------------ //
  // USE MEMO/EFFECT & MOUSE EVENTS
  // ------------------------------ //
  function lockScroll(event: any) {
    event.preventDefault();
  }

  const generateCreatureSpawnPoints = useMemo(() =>
    generateCreatureSpawns(currCreature, currMaturity),
    [currCreature, currMaturity, zoom]);

  const generateCreatureSpawnText = useMemo(() =>
    generateCreatureSpawnsText(currCreature, currMaturity),
    [currCreature, currMaturity, zoom]);

  const generateCreatureSpawnDetails = useMemo(() =>
    displaySpawnDetails(currCreature, currMaturity),
    [currCreature, currMaturity]);

  const generateTeleports = useMemo(() => {
    return teleportsList.map(tp =>
      <TeleportGroup className={'teleports'} id={tp.Name} key={tp.Name}>
        <image href={tpIcon}
          width={zoom > 1 ? 8 / (zoom * 0.7) : 8}
          height={zoom > 1 ? 8 / (zoom * 0.7) : 8}
          x={getMapCoordsXFromEUCoords(tp.Longitude) - (zoom > 1 ? (2 / (zoom * 0.7)) : 4)}
          y={getMapCoordsYFromEUCoords(tp.Latitude) - (zoom > 1 ? (4 / (zoom * 0.7)) : 4)} />
        <text style={{ fontSize: `${14 / (zoom * 0.9)}px`, zIndex: 999 }}
          x={getMapCoordsXFromEUCoords(tp.Longitude) - (zoom > 1 ? 10 / (zoom * 0.7) : 10)}
          y={getMapCoordsYFromEUCoords(tp.Latitude) - (zoom > 1 ? 10 / (zoom * 0.7) : 10)}>
          {tp.Name}
        </text>
      </TeleportGroup>
    );
  }, [zoom]);

  const generateOutposts = useMemo(() => {
    return outpostsList.map(tp =>
      <OutpostGroup className={'outposts'} id={tp.id + tp.name} key={tp.id + tp.name}>
        <image href={tpIcon} filter='hue-rotate(184deg)'
          width={zoom > 1 ? 8 / (zoom * 0.7) : 8}
          height={zoom > 1 ? 8 / (zoom * 0.7) : 8}
          x={getMapCoordsXFromEUCoords(tp.euX) - (zoom > 1 ? 4 / (zoom * 0.7) : 4)}
          y={getMapCoordsYFromEUCoords(tp.euY) - (zoom > 1 ? 4 / (zoom * 0.7) : 4)} />
        <text style={{ fontSize: `${14 / (zoom * 0.9)}px`, zIndex: 999 }}
          x={getMapCoordsXFromEUCoords(tp.euX) - (zoom > 1 ? 10 / (zoom * 0.7) : 10)}
          y={getMapCoordsYFromEUCoords(tp.euY) - (zoom > 1 ? 10 / (zoom * 0.7) : 10)}>
          {tp.name}
        </text>
      </OutpostGroup>
    );
  }, [zoom]);

  const generateGridLines = useMemo(() => {
    generateGrid();
  }, [generateGrid]);

  const generatePvpZones = useMemo(() => {
    return pvpZonesList.map(z => <PvpGroup className={'pvpZones'} id={z.id.toString()} key={z.id} isDeadly={z.deadly}>
      <polygon points={getMapPointsFromEUCoords(z.pos)} />
      <text style={{ fontSize: `${14 / (zoom * 0.9)}px`, zIndex: 9999 }}
        x={getMapCoordsXFromEUCoords(z.pos.split(' ')[0].split(',')[0] as unknown as number) - (zoom > 1 ? 10 / (zoom * 0.7) : 10)}
        y={getMapCoordsYFromEUCoords(z.pos.split(' ')[0].split(',')[1] as unknown as number) - (zoom > 1 ? 10 / (zoom * 0.7) : 10)}>
        {z.deadly ? 'Contaminated Zone' : 'PVP Zone'}
      </text>
    </PvpGroup>
    );
  }, [zoom]);

  const generateLandAreas = useMemo(() => {
    return landAreasList.map(z =>
      <LandArea points={getMapPointsFromEUCoords(z.border, z.addOffsetXY)} id={z.id + z.border.split(' ')[0]} key={z.id + z.border.split(' ')[0]} style={{
        zIndex: 1, fill: 'rgb(114 36 121)', opacity: 0.8
      }} onMouseUp={() => {
        setSelectedLandArea(z as landAreaDetails);
        setShowLandAreasDetailsOverlay(true);
      }} />
    );
  }, [zoom]);

  const getLandAreaMobDetails = useMemo(() => {
    if (selectedLandArea !== undefined && selectedLandArea.mobsList !== undefined) {
      if (selectedLandArea.mobsList.length > 0) {
        return selectedLandArea.mobsList.sort((a, b) => b.DensitySize - a.DensitySize).map(mob =>
          <li key={mob.name + mob.DensitySize} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '1rem', fontSize: 14 }}>
            <div>
              <span>{mob.name} </span>
              <span>{`[${mob.maturity.min}${mob.maturity.max.length > 0 ? `${' - ' + mob.maturity.max}` : ''}]`} </span>
              <div style={{ margin: '0 auto', maxWidth: '200px', display: 'flex' }}>
                <SpawnDetailsDensityBar style={{ margin: '0.5rem 0 0 0' }}>
                  <SpawnDetailsDensityBarFill style={{ float: 'left' }} />
                  <SpawnDetailsDensityBarPercentage style={{ width: `${100 - mob.DensitySize}%` }} />
                </SpawnDetailsDensityBar>
              </div>
            </div>
          </li>
        );
      }
      else {
        return <li><span style={{ font: 'menu' }}>N/A</span></li>
      }
    }
  }, [selectedLandArea]);

  const getLandAreaResourceList = useMemo(() => {
    if (selectedLandArea !== undefined && selectedLandArea.resourceList !== undefined) {
      return <div>
        <div>
          <p style={{ margin: 0 }}>Ores:</p>
          <span style={{ font: 'menu' }}>{selectedLandArea.resourceList.ores.join("").length > 0
            ? selectedLandArea.resourceList.ores.join(", ")
            : "N/A"}</span>
        </div>
        <div>
          <p style={{ margin: '0.5em 0 0 0' }}>Enmatter:</p>
          <span style={{ font: 'menu' }}>{selectedLandArea.resourceList.enmatter.join("").length > 0
            ? selectedLandArea.resourceList.enmatter.join(", ")
            : "N/A"}</span>
        </div>
      </div>
    }
  }, [selectedLandArea]);

  useEffect(() => {
    const el = document.getElementById("map");

    if (el !== null) {
      el.onwheel = lockScroll;
    }

    if (zoom) {
      setViewBoxHeight(maxViewBoxSize / zoom);
      setViewBoxWidth(maxViewBoxSize / zoom);
      setViewBoxXOffset(viewBoxXOffset);
      setViewBoxYOffset(viewBoxYOffset);
    }
  }, [zoom, viewBoxXOffset, viewBoxYOffset]);

  useEffect(() => {
    setTimeout(() => {
      setIsWaypointCopied(false);
    }, 2500);
  }, [setIsWaypointCopied, isWaypointCopied]);

  const onWheel = (e: React.WheelEvent<SVGSVGElement>) => {
    const newZoom = Math.min(maxZoom, Math.max(minZoom, zoom - e.deltaY * 0.005));
    const zoomFactor = newZoom / zoom;

    setZoom(newZoom);
    setCurrZoomFactor(zoomFactor);

    var vbWidth = viewBoxWidth / zoomFactor;
    var vbHeight = viewBoxHeight / zoomFactor;

    var vbX = viewBoxXOffset + (viewBoxWidth - vbWidth) / 2;
    var vbY = viewBoxYOffset + (viewBoxHeight - vbHeight) / 2;

    if (newZoom <= 1) {
      setViewBoxWidth(maxViewBoxSize);
      setViewBoxHeight(maxViewBoxSize);
      setViewBoxXOffset(0);
      setViewBoxYOffset(0);
    }
    else if (newZoom !== zoom) {
      setViewBoxWidth(vbWidth);
      setViewBoxHeight(vbHeight);
      setViewBoxXOffset(vbX);
      setViewBoxYOffset(vbY);
    }
  };

  const onMouseDown = (e: React.MouseEvent<SVGSVGElement>) => {
    setMouseDown(true);
    setLastMousePos({ x: e.clientX, y: e.clientY });
    const svg = e.currentTarget;
    const rect = svg.getBoundingClientRect();
    const svgX = e.clientX - rect.left;
    const svgY = e.clientY - rect.top;

    //Client X = distance from browser window left
    //Client Y = distance from browser window top

    var vbClientDiffX = e.clientX - svgX;
    var vbClientDiffY = e.clientY - svgY;

    var vbX = (e.clientX / zoom) + viewBoxXOffset - (vbClientDiffX / zoom);
    var vbY = (e.clientY / zoom) + viewBoxYOffset - (vbClientDiffY / zoom);

    var currX = Number((minEuX + (vbX * pixelToEuX)).toFixed(2));
    var currY = Number((minEuY + (vbY * pixelToEuY)).toFixed(2));

    if (isTesting) {
      var newPoint = currX + "," + currY;
      setMappingAreaPoints(mappingAreaPoints + " " + newPoint);
    }

    setCurrentEUPos({ x: currX, y: currY });
  };

  const onMouseUp = () => {
    setMouseDown(false);
  };

  const onMouseMove = (e: React.MouseEvent<SVGSVGElement>) => {
    if (!mouseDown || zoom === minZoom) return;

    const deltaX = e.clientX - lastMousePos.x;
    const deltaY = e.clientY - lastMousePos.y;

    var vbX = (viewBoxXOffset - deltaX);
    var vbY = (viewBoxYOffset - deltaY);

    setViewBoxXOffset(vbX);
    setViewBoxYOffset(vbY);

    setLastMousePos({ x: e.clientX, y: e.clientY });
  };

  const settingsOptions = [
    'Server Grid',
    'Teleports',
    'Outposts',
    'Pvp Zones',
    'Land Areas'
  ]

  return (
    <Main id="main">
      {showLandAreaDetailsOverlay &&
        <LandAreaDetailsOverlay onClick={() => setShowLandAreasDetailsOverlay(false)}>
          <LandAreaDetailsContainer onClick={e => e.stopPropagation()}>
            <h2 style={{ marginBottom: 0, marginTop: '0.5em' }}>{selectedLandArea.Name}</h2>
            <span style={{ font: 'small-caption' }}>Owned by <span style={{ color: 'rgb(251 111 111)' }}>{selectedLandArea.owner}</span></span>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1 }}>
                <p style={{ marginBottom: 0 }}>Waypoints (click to copy):</p>
                <p style={{ font: 'small-caption', fontSize: 11, marginBottom: 0, marginTop: '0.2em' }} onClick={() => {
                  navigator.clipboard.writeText(`/wp [Calypso, ${findMiddlePoint(selectedLandArea.border).x.toFixed(0)}, ${findMiddlePoint(selectedLandArea.border).y.toFixed(0)}, 150, ${selectedLandArea.Name + " Mid"}]`);
                }}>
                  <div style={{ width: '90%', margin: '0 auto' }}>
                    <LandAreaDetailsWaypoint>
                      <p style={{ marginBottom: 0, color: 'rgb(251 111 111)' }}>Mid-point:</p>
                      {`/wp [Calypso, ${findMiddlePoint(selectedLandArea.border).x.toFixed(0)}, ${findMiddlePoint(selectedLandArea.border).y.toFixed(0)}, 150, ${selectedLandArea.Name + " Mid"}]`}
                    </LandAreaDetailsWaypoint>
                  </div>
                </p>
                {selectedLandArea.feederPos !== "N/A" &&
                  <p style={{ font: 'small-caption', fontSize: 11, marginBottom: 0, marginTop: '0.2em' }} onClick={() => {
                    navigator.clipboard.writeText(`/wp [Calypso, ${selectedLandArea.feederPos.replace(',', ', ')}, ${selectedLandArea.Name + " Feeder"}]`);
                  }}>
                    <div style={{ width: '90%', margin: '0 auto' }}>
                      <LandAreaDetailsWaypoint>
                        <p style={{ marginBottom: 0, color: 'rgb(251 111 111)' }}>Feeder:</p>
                        {`/wp [Calypso, ${selectedLandArea.feederPos.replace(',', ', ')}, ${selectedLandArea.Name + " Feeder"}]`}
                      </LandAreaDetailsWaypoint>
                    </div>
                  </p>
                }
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1 }}>
                <p style={{ marginBottom: 0 }}>Taxes:</p>
                <ul style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', margin: '0.5em 2em 0 2em' }}>
                  <li style={{ fontSize: 16 }}>Hunting: <span>{selectedLandArea.huntingTax} %</span></li>
                  <li style={{ fontSize: 16, marginTop: '1em' }}>Mining: <span>{selectedLandArea.miningTax} %</span></li>
                  {/* <li style={{ fontSize: 14 }}>Shopping: <span>{selectedLandArea.shoppingTax} %</span></li> */}
                </ul>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1 }}>
                <p style={{ marginBottom: 0, marginTop: '1em' }}>Creatures:</p>
                <ul style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0.5em 2em 0.5rem 2em' }}>
                  {getLandAreaMobDetails}
                </ul>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '100%', flex: 1 }}>
                <div style={{ marginTop: '1em' }}>
                  {getLandAreaResourceList}
                </div>
              </div>
            </div>
            {/* {selectedLandArea.notes.length > 0 && <h4 style={{ marginBottom: 0 }}>Notes: <p style={{ margin: 0, font: 'menu' }}>{selectedLandArea.notes}</p></h4>} */}
          </LandAreaDetailsContainer>
        </LandAreaDetailsOverlay>
      }
      {showChangeLogsOverlay &&
        <ChangelogsOverlay onClick={() => setShowChangeLogsOverlay(false)}>
          <ChangelogsContainer onClick={(e) => e.stopPropagation()}>
            {buildChangeLogs()}
          </ChangelogsContainer>
        </ChangelogsOverlay>
      }
      <OptionsContainer>
        <Dropdowns>
          {/* <PlanetDropdown label='Planets' defaultValue={'Calypso'} placeholder='Select Planet' onChange={e => setCurrPlanet(e.currentTarget.value)}>
            {generatePlanetsList()}
          </PlanetDropdown> */}
          <LandAreaDropdown label='LandAreas' defaultValue={'None'} placeholder='Select LandArea' onChange={e => {
            setCurrLandArea(e.currentTarget.value);
            if (e.currentTarget.value === '') {
              setZoom(1);
              setViewBoxXOffset(0);
              setViewBoxYOffset(0);
            }
            else {
              var landArea = landAreasList.find(x => x.Name === e.currentTarget.value);
              if (landArea) {
                navigateToMidPosition(landArea.border);
              }
            }
          }}>
            {generateLandAreaList()}
          </LandAreaDropdown>
          <CreatureDropdown label='Creatures' placeholder='Select Creature' onChange={e => setCurrCreature(e.currentTarget.value)}>
            {generateCreatureList()}
          </CreatureDropdown>
          <MaturityDropdown label='Maturity' defaultValue={'all'} placeholder='Select Maturity' onChange={e => setCurrMaturity(e.currentTarget.value)}>
            <option value='all'>All</option>
            <option value='small'>Small</option>
            <option value='mid'>Mid</option>
            <option value='big'>Big</option>
          </MaturityDropdown>
          <MultiSelect>
            <Label key={settingsOptions[4]}>
              <input
                type="checkbox"
                value={settingsOptions[4]}
                checked={showLandAreas}
                onChange={() => setShowLandAreas(!showLandAreas)}
              />
              {settingsOptions[4]}
            </Label>
            <Label key={settingsOptions[1]}>
              <input
                type="checkbox"
                value={settingsOptions[1]}
                checked={showTeleports}
                onChange={() => setShowTeleports(!showTeleports)}
              />
              {settingsOptions[1]}
            </Label>
            <Label key={settingsOptions[2]}>
              <input
                type="checkbox"
                value={settingsOptions[2]}
                checked={showOutposts}
                onChange={() => setShowOutposts(!showOutposts)}
              />
              {settingsOptions[2]}
            </Label>
            <Label key={settingsOptions[3]}>
              <input
                type="checkbox"
                value={settingsOptions[3]}
                checked={showPvp}
                onChange={() => setShowPvp(!showPvp)}
              />
              {settingsOptions[3]}
            </Label>
            <Label key={settingsOptions[0]}>
              <input
                type="checkbox"
                value={settingsOptions[0]}
                checked={showGridLines}
                onChange={() => setShowGridLines(!showGridLines)}
              />
              {settingsOptions[0]}
            </Label>
            {/* <option key="showGridLines" id="showGridLines" onChange={() => setShowGridLines(!showGridLines)} />
            <option key="showTps" id="showTps" onChange={() => setShowTeleports(!showTeleports)} />
            <option key="showTps" id="showOutposts" onChange={() => setShowOutposts(!showOutposts)} />
            <option key="showTps" id="showPvp" onChange={() => setShowPvp(!showPvp)} /> */}
          </MultiSelect>
        </Dropdowns>
      </OptionsContainer>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative' }}>
        <MapContainer
          id="map"
          width="768px"
          height="768px"
          viewBox={`${viewBoxXOffset} ${viewBoxYOffset} ${viewBoxWidth} ${viewBoxHeight}`}
          onWheel={onWheel}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseUp}
        >
          <image href={fullMap} id="mapImage" width="768px" height="768px" onContextMenu={e => e.preventDefault()} />
          {showPvp && generatePvpZones}
          {generateCreatureSpawnPoints}
          {showLandAreas && generateLandAreas}
          {showTeleports && generateTeleports}
          {showOutposts && generateOutposts}
          {showGridLines && generateGridLines}
          {generateCreatureSpawnText}
          {/* <CreatureSpawnWaypointMarker
            id="creature-spawn-waypoint"
            points={waypointPoints(waypointIndicatorPos.x + 54, waypointIndicatorPos.y + 44)} /> */}
        </MapContainer>
        {currCreature !== 'all' && currCreature !== 'none' && currCreature !== 'None' && currCreature !== '' &&
          <MapDetailsContainer>
            <MapSpawnDetailsHeader>
              <span>Name: <a style={{ margin: 0, padding: 0, color: 'aquamarine' }} href={"http://www.entropiawiki.com/Info.aspx?chart=Mob&id=" + currCreatureWikiId} target='_blank'>{currCreature}</a></span>
            </MapSpawnDetailsHeader>
            {generateCreatureSpawnDetails}
          </MapDetailsContainer>
        }
        {isWaypointCopied &&
          <div style={{ position: 'absolute', width: '200px', height: '50px', left: '40%', top: '42%', borderRadius: '20px', backgroundColor: 'rgba(0,0,0,0.4)', alignContent: 'center' }}>
            Waypoint copied
          </div>
        }
      </div>
      <p>Latitude: {currentEUPos.x.toFixed(0)} - Longitude: {currentEUPos.y.toFixed(0)}</p>
      <button onClick={() => setShowChangeLogsOverlay(true)}>Changelogs</button>
      {isTesting && (
        <div style={{ padding: '0 0 1em 0' }}>
          <button onClick={() => setMappingAreaPoints("")}>CLEAR</button>
          <button onClick={() => navigator.clipboard.writeText(mappingAreaPoints.trimStart())}>COPY</button>
          <p>{mappingAreaPoints}</p>
        </div>
      )}
    </Main>
  );
}

