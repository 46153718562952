import React, { useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const PayoutLine = styled.div({
  position: 'absolute',
  height: '10px',
  width: '400px',
  borderTop: '3px solid #fff',
  transform: 'translateY(45%)',
  pointerEvents: 'none'
})

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -20px, 0);
  }

  70% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

// const Symbol = styled.img`
//   animation: ${bounce} 1s linear infinite;
//   max-width: 50px;
//   max-height: 50px; 
//   padding: 1em;
// `;

export const SlotMachine: React.FC = () => {
  // const images: Images = {
  //     "Cherry": "https://cdn3.iconfinder.com/data/icons/slot-machine-symbols-filled-outline/256/cherry-512.png",
  //     "Bell": "https://cdn3.iconfinder.com/data/icons/slot-machine-symbols-filled-outline/256/bell-512.png",
  //     "Lemon": "https://static.vecteezy.com/system/resources/previews/018/931/687/original/yellow-lemon-icon-png.png",
  //     "Orange": "https://cdn-icons-png.flaticon.com/512/261/261815.png",
  //     "Star": "https://cdn-icons-png.flaticon.com/512/541/541415.png",
  //     "Skull": "https://e7.pngegg.com/pngimages/474/977/png-clipart-skull-computer-icons-skull-face-monochrome-thumbnail.png"
  // };

  const images: Images = {
    "Cherry": "http://www.entropiawiki.com/images/gallery/27Normal.jpg",
    "Bell": "http://www.entropiawiki.com/images/gallery/36Normal.jpg",
    "Lemon": "http://www.entropiawiki.com/images/gallery/24Normal.jpg",
    "Orange": "http://www.entropiawiki.com/images/gallery/286Normal.jpg",
    "Star": "http://www.entropiawiki.com/images/gallery/8Normal.jpg",
    "Skull": "http://www.entropiawiki.com/images/gallery/31Normal.jpg"
  };

  type Payouts = { [key: string]: number };
  type Probabilities = { [key: string]: number };
  type Images = { [key: string]: string };

  const payouts: Payouts = { "Cherry": 2, "Bell": 5, "Lemon": 10, "Orange": 20, "Star": 100, "Skull": 500 };
  const probabilities: Probabilities = { "Cherry": 0.4, "Bell": 0.2, "Lemon": 0.15, "Orange": 0.15, "Star": 0.09, "Skull": 0.01 };

  const SlotMachine = () => {
    const [balance, setBalance] = useState(5000);
    const [bet, setBet] = useState(1);
    const [reels, setReels] = useState([new Array(3).fill("Cherry"), new Array(3).fill("Cherry"), new Array(3).fill("Cherry")]);
    const [totalPayout, setTotalPayout] = useState<number>(0);
    const [hasStarted, setHasStarted] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [shouldBounce] = useState(true);

    const symbols = Object.keys(payouts);
    const weightedSymbols = symbols.flatMap(symbol => Array.from({ length: Math.round(probabilities[symbol] * 100) }, () => symbol));

    const spinReels = () => {
      if (!hasStarted && bet <= balance) {
        setHasStarted(!hasStarted);
      }

      if (balance >= bet && bet > 0) {
        const spinDuration = 1500; // 1.5 seconds in milliseconds
        const spinInterval = 50; // 50 milliseconds between symbol changes

        // Disable the spin button
        setButtonDisabled(true);
        setTotalPayout(0);

        // Initialize the reels with random symbols
        const newReels = reels.map(row =>
          row.map(() => weightedSymbols[Math.floor(Math.random() * weightedSymbols.length)])
        );
        setReels(newReels);

        // Spin the reels by changing the symbols at a fixed interval
        const intervalId = setInterval(() => {
          const newReels = reels.map(row =>
            row.map(() => weightedSymbols[Math.floor(Math.random() * weightedSymbols.length)])
          );
          setReels(newReels);
        }, spinInterval);

        // Wait for 3 seconds before landing
        setTimeout(() => {
          // Stop the spinning by clearing the interval
          clearInterval(intervalId);

          setReels(newReels);
          // Calculate the payout and update the balance
          const payoutRows = newReels.map(row => {
            const symbol = row[0];
            return row.every(r => r === symbol) ? payouts[symbol] * bet : 0;
          });

          const newPayout = payoutRows.reduce((a, b) => a + b, 0);
          setTotalPayout(newPayout);
          setBalance(balance + newPayout - bet);

          // Enable the spin button
          setButtonDisabled(false);
        }, spinDuration);
      }
    };

    const payoutLine = (line: number) => {
      const symbols = reels[line];
      if (symbols.every((s) => s === symbols[0])) {
        return <PayoutLine style={{ top: 60 + (line * 135), left: '0%' }}></PayoutLine>;
      } else {
        return null;
      }
    };

    return (
      <div style={{ backgroundColor: 'rgba(20,20,20,1)', color: 'white', width: 'fit-content', padding: '1em', margin: '2em auto 2em auto', border: '2px solid white', borderRadius: '12px' }}>
        <h1 style={{ marginTop: 0, fontWeight: 'bold' }}>Ripcrazy Slots</h1>
        <p>Balance: {balance} PED</p>
        <p>Bet: {bet} PED</p>
        <div style={{ position: "relative" }}>
          {reels.map((row, i) => (
            <div key={i}>
              {row.map((r, j) => <img style={{ minWidth: '100px', maxWidth: '100px', minHeight: '100px', maxHeight: '100px', padding: '1em', animation: shouldBounce ? `${bounce} 1s linear infinite` : "" }} key={j} src={images[r]} alt={r} />)}
              {hasStarted && totalPayout > 0 && payoutLine(i)}
            </div>
          ))}
        </div>
        <div>
          <div>Total Payout: {totalPayout} PED</div>
          <button style={{ width: 'fit-content', height: 'fit-content', padding: '0.3em 0.5em', fontWeight: 'bold', marginRight: '0.5em' }} onClick={spinReels} disabled={buttonDisabled}>
            SPIN
          </button>
          <input id="betValue" style={{ padding: '0.3em 0.5em', fontWeight: 'bold', textAlign: 'center' }} type="number" value={bet} min="1" max={balance} onChange={(e) => setBet(parseInt(e.target.value))} />
        </div>
      </div>
    );
  }

  return SlotMachine();
}