import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Input, InputSize } from '../../inputs/Input';

const Main = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(20,20,20,1)',
    color: 'white'
  });

const InputOutputContainer = styled.div(({theme}:any) => ({
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',

    [theme.breakpoints.mobileAndLower]: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center'
    }
}));

const InputRow = styled.div(({theme}:any) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 0.5em',

    'input': {
        maxWidth: '200px',
        minWidth: '200px',
        minHeight: '25px !important',
    },

    [theme.breakpoints.mobileAndLower]: {
        margin: 0
    }
}));

export const ToolContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    alignItems: 'center',
    alignSelf: 'center',
    height: 'auto',
    padding: '0.5em',
    margin: '1em',
    border: '1px solid white',
    boxSizing: 'border-box',
    backgroundColor: 'rgba(25,25,25,1)',

    h2: {
        margin: '0 auto'
    },

    small: {
        marginBottom: '1.5em'
    }
});

export const SuggestionsContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center'
});

export const SuggestionContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid white',
    flexGrow: 1,
    margin: '0.3em'
});

export const AuctionMarkupCalc: React.FC = () => {
    const [itemValue, setItemValue] = useState(0.1);
    const [totalItems, setTotalItems] = useState(50)
    const [finalPrice, setFinalPrice] = useState(5);
    const [suggestions, setSuggestions] = useState([0, 0, 0]);
    const [suggestionStep, setSuggestionStep] = useState(1);

    useEffect(() => {
        var initialValue = itemValue * totalItems;

        var s1 = ((finalPrice - suggestionStep) / initialValue) * 100;
        var s2 = (finalPrice / initialValue) * 100;
        var s3 = ((finalPrice + suggestionStep) / initialValue) * 100;

        setSuggestions([s1, s2, s3]);
    }, [itemValue, finalPrice, totalItems, suggestionStep]);

    return <Main>
        <ToolContainer>
            <h2>AUCTION CALCULATOR</h2>
            <small>Use to determine what percentage you can sell items for at what price.</small>
            <InputOutputContainer>
                <InputRow>
                    <span>{'VALUE PER ITEM'}</span>
                    <Input style={{marginBottom: '20px'}} onChange={e => setItemValue(parseFloat(e.currentTarget.value))} type="number" step={0.01} min={0.01} value={itemValue.toString()} placeholder="Value per item" />
                </InputRow>
                <InputRow>
                    <span>{'TOTAL ITEMS'}</span>
                    <Input style={{marginBottom: '20px'}} onChange={e => setTotalItems(parseInt(e.currentTarget.value))} type="number" step={1} min={1} value={totalItems.toString()} placeholder="Total items" />
                </InputRow>
                <InputRow>
                    <span>{'AUCTION SELL PRICE (PED)'}</span>
                    <Input style={{marginBottom: '20px'}} onChange={e => setFinalPrice(parseInt(e.currentTarget.value))} type="number" step={1} min={1} value={finalPrice.toString()} placeholder="Final price with markup" />
                </InputRow>
            </InputOutputContainer>
            <InputRow>
                <span>{'STEP SIZE (PED)'}</span>
                <Input inputSize={InputSize.Small} style={{marginBottom: '20px'}} onChange={e => setSuggestionStep(parseInt(e.currentTarget.value))} type="number" step={1} min={1} value={suggestionStep.toString()} placeholder="Suggestion ped step size" />
            </InputRow>
            <p style={{marginTop: 0}}>Suggestions:</p>
            <SuggestionsContainer>
                <SuggestionContainer>
                    <p>-{suggestionStep} PED</p>
                    <div>
                        <p>{suggestions[0].toFixed(2)}%</p>
                    </div>
                </SuggestionContainer>
                <SuggestionContainer>
                    <p>CURRENT ({finalPrice}PED)</p>
                    <div>
                        <p>{suggestions[1].toFixed(2)}%</p>
                    </div>
                </SuggestionContainer>
                <SuggestionContainer>
                    <p>+{suggestionStep} PED</p>
                    <div>
                        <p>{suggestions[2].toFixed(2)}%</p>
                    </div>
                </SuggestionContainer>
            </SuggestionsContainer>
            <small style={{width: '80%', color: 'gray'}}>For example you want to sell 50 items of 10pec value each, you can see what % you get selling it at 4ped, 5ped or 6ped. You can also change total items or value per item to see what % you get for the same auction price.</small>
        </ToolContainer>
    </Main>
  }
