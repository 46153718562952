import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ActionButton } from '../../buttons/ActionButton';
import { Dropdown } from '../../inputs/Dropdown';
import { AllCraftingMaterials } from '../EntropiaMap/data/craftingMaterials';
import { AuctionMarkupCalc } from '../AuctionMarkupCalc/AuctionMarkupCalc';

const Main = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgba(20,20,20,1)',
    color: 'white'
  });

  const ToolDivider = styled.div({
    border: '1px solid',
    maxWidth: '80%',
    minWidth: '80%',
    margin: '20px auto',
    borderImage: 'linear-gradient(to right, rgba(0,0,0,0) 0%, #0dcaf0 20%, #0dcaf0 80%, rgba(0,0,0,0) 100%) 1'
  });

  const CalcContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    alignItems: 'center',
    alignSelf: 'center'
});

const InputOutputContainer = styled.div(({theme}:any) => ({
    display: 'flex',
    flexDirection: 'row',
    width: 'fit-content',

    'input': {
        maxWidth: '200px'
    },

    [theme.breakpoints.mobileAndLower]: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center'
    }
}));

const InputContainer = styled.div(({theme}:any) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    marginRight: '30px',

    [theme.breakpoints.mobileAndLower]: {
        width: '100%  !important',
        marginRight: 0,
    }
}));

const InputRows = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    'input': {
        width: '95%'
    }
});

const InputRow = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',

    'input': {
        minHeight: '25px !important'
    }
});

const Output = styled.div(({theme}:any) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',

    [theme.breakpoints.mobileAndLower]: {
        width: '100%  !important', 
    }
}));

const OutputRows = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    
    'input': {
        width: '95%'
    }
});

const OutputRow = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',

    'input': {
        minHeight: '25px !important'
    }
});

const AddDeleteRows = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-evenly',
    paddingLeft: '20px',
    paddingRight: '20px',
    alignSelf: 'center',

    'h2': {
        padding: '10px',
        fontSize: '30px',
        margin: 0,

        '&:hover': {
            cursor: 'pointer'
        }
    }
});

const InputCalculations = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
});

const InputUnitTableTitle = styled.h3({
    textAlign: 'left',
    marginBottom: 0
});

const InputUnitTable = styled.table({
    border: '1px solid white',
    padding: '10px',

    'th': {
        textAlign: 'center'
    }
});

const ResultContainer = styled.div<{show: boolean}>(({show}) => ({
    visibility: show ? 'visible' : 'hidden'
}));

const MaterialDropdown = styled(Dropdown)({
    width: '100%',
    margin: 0,
})

const generateInputs = (isManual?: boolean, isDelete?: boolean) => {
    let elem = document.getElementById('inputRows');

    if(!isDelete) {
        // <MaterialDropdown>{GetCraftingMaterialsList()}</MaterialDropdown>}
        let ItemContainer = document.createElement('div');
            ItemContainer.style.marginTop = '5px';
            ItemContainer.style.display = 'flex';
            ItemContainer.style.flexDirection = 'column';
            ItemContainer.style.alignItems = 'center';

        if(isManual) {
            let rowItemName = document.createElement('input');
            rowItemName.type = 'text';
            rowItemName.placeholder = 'Item Name';
            rowItemName.maxLength = 100;
            rowItemName.style.minHeight = '25px';

            let rowTotalTT = document.createElement('input');
            rowTotalTT.type = 'number';
            rowTotalTT.placeholder = 'TT value per item'
            rowTotalTT.min = "0";
            rowTotalTT.step = "0.01";
            rowTotalTT.style.minHeight = '25px';

            ItemContainer.appendChild(rowItemName);
            ItemContainer.appendChild(rowTotalTT);
        } else {
            //customElements.define('custom-dropdown', Dropdown);

            //let rowMaterialItem = document.createElement('custom-dropdown');
        }

        let rowItemsPerClick = document.createElement('input');
        rowItemsPerClick.type = 'number';
        rowItemsPerClick.placeholder = 'Items Per Click';
        rowItemsPerClick.min = "1";
        rowItemsPerClick.style.minHeight = '25px';

        let rowItemMarkup = document.createElement('input');
        rowItemMarkup.type = 'number';
        rowItemMarkup.placeholder = 'Markup (default 100) %'
        rowItemMarkup.min = "100";
        rowItemMarkup.step = "0.01";
        rowItemMarkup.style.minHeight = '25px';

        ItemContainer.appendChild(rowItemsPerClick);
        ItemContainer.appendChild(rowItemMarkup);

        elem && elem.appendChild(ItemContainer);
    } else {
        let childNodes = elem && elem.childNodes[elem.childElementCount - 1];
   
        if(elem && elem.childElementCount > 1) {
            childNodes && elem.removeChild(childNodes);
        }
    }
}

// const generateOutputs = (isDelete?: boolean) => {
//     let elem = document.getElementById('outputRows');

//     if(!isDelete) {
//         let ItemContainer = document.createElement('div');
//         ItemContainer.style.marginTop = '26px';

//         let rowItemCount = document.createElement('input');
//         rowItemCount.type = 'number';
//         rowItemCount.placeholder = 'Item Count';
//         rowItemCount.min = "1";
    
//         let rowTotalTT = document.createElement('input');
//         rowTotalTT.type = 'number';
//         rowTotalTT.placeholder = 'Total TT Value'
//         rowTotalTT.min = "0";
//         rowTotalTT.step = "0.01";

//         ItemContainer.appendChild(rowItemCount);
//         ItemContainer.appendChild(rowTotalTT);
    
//         elem && elem.appendChild(ItemContainer);
//     } else {
//         let childNodes = elem && elem.childNodes[elem.childElementCount - 1];
        
//         if(elem && elem.childElementCount > 1) {
//             childNodes && elem.removeChild(childNodes);
//         }
//     }
// }

const caluclateInputCost = () => {
    let elem = document.getElementById('inputRows'); //input rows with tt value & markup
    let clicksElem = document.getElementById('clickAmount'); //amount of clicks in craft
    let children = elem && elem.childNodes; //amount of input groups
    let totalCost = 0;

    //remove all cost per unit children before recalculating and adding new ones
    let cpuElem = document.getElementById('costPerUnit');
    while(cpuElem && cpuElem.childElementCount > 1) {
        cpuElem.lastChild && cpuElem.removeChild(cpuElem.lastChild);
    }

    if(children && children.length > 0) {
        let count = 1;

        children.forEach(x => {
            let child = x as HTMLInputElement;
            let rName = child.childNodes[0] as HTMLInputElement;
            // let rCount = child.childNodes[1] as HTMLInputElement;
            let rItemsPerClick = child.childNodes[1] as HTMLInputElement;
            let rPerItemCost = child.childNodes[2] as HTMLInputElement;
            let rMarkup = child.childNodes[3] as HTMLInputElement;
            let rClicks = clicksElem as HTMLInputElement;

            let unitRow = document.createElement('tr');

            if(parseFloat(rClicks.value) > 0) {
                let itemName = rName.value ? rName.value : count.toString();
                let itemCount = parseFloat(rClicks.value ? rClicks.value : '1') * parseFloat(rItemsPerClick.value ? rItemsPerClick.value : '1');
                let tt = parseFloat(rPerItemCost.value ? rPerItemCost.value : '0' ) * itemCount;
                let mu = parseFloat(rMarkup.value ? rMarkup.value : '100') / 100;
                totalCost += tt * mu;
    
                //calculate cost per unit for each input
                let unitName = document.createElement('td');
                unitName.textContent = itemName;
                unitRow && unitRow.appendChild(unitName);

                let unitData = document.createElement('td');
                unitData.textContent = `${(totalCost / itemCount).toFixed(4)} ped`;
                unitRow && unitRow.appendChild(unitData);
            }

            cpuElem && cpuElem.appendChild(unitRow);
            count++;
        });
    }

    return parseFloat(totalCost.toFixed(2));
}

const calculateOutputMarkupNeeded = (getProfit?: boolean) => {
    let elem = document.getElementById('outputRows');
    let restElem = document.getElementById('restValue');
    let totOutputElem = document.getElementById('totOutputVal');
    let children = elem && elem.childNodes; //amount of input groups
    let totalMU = 0;
    // let auCost = 0;
    // let itemCount = 0;
    let totalInputCost = 0;
    let tt = 0;
    let restVal = 0;
    let outputDiff = 0;
    let diff = 0;

    if(children && children.length > 0) {
        children.forEach(x => {
            let child = x as HTMLInputElement;
            // let outName = child.childNodes[0] as HTMLInputElement;
            let outCount = child.childNodes[1] as HTMLInputElement;
            let outTotTT = child.childNodes[2] as HTMLInputElement;
            let rest1 = restElem as HTMLInputElement;
            let totOutputVal = totOutputElem as HTMLSpanElement;

            if(parseFloat(outCount.value) > 0) {
                // itemCount = parseFloat(outCount.value ? outCount.value : '1');
                totalInputCost = caluclateInputCost();
                tt = parseFloat(outTotTT.value ? outTotTT.value : '0.01' );
                restVal = parseFloat(rest1.value) > 0 ? parseFloat(rest1.value) : 0;
                outputDiff = totalInputCost - restVal;
                diff = outputDiff / tt;

                // auCost = 0.5 + ((74.625 * diff) / (1492.5 + diff));

                totOutputVal.innerHTML = (tt + restVal).toFixed(2) + ' ped';
                totalMU = diff * 100;
            }
        });
    }

    return {
        markup: parseFloat(totalMU.toFixed(2)),
        ttOutput: parseFloat((tt + restVal).toFixed(2))
    };
}

export const AuctionCalc: React.FC = () => {
    const [inputCost, setInputCost] = useState(0.0);
    const [outputMarkup, setOutputMarkup] = useState(100.0);
    const [outputCost, setOutputCost] = useState(0.0);
    // const [auctionFeeCost, setAuctionFeeCost] = useState(0.0);
    const [showResult, setShowResult] = useState(false);
    const [clickAmount, setClickAmount] = useState(0);
    const [manualInput] = useState(true);

    useEffect(() => {
        let output = calculateOutputMarkupNeeded();
        setOutputMarkup(output.markup);
        setOutputCost(output.ttOutput);
    }, [inputCost, outputMarkup]);

    const getTTProfit = () => {
        return (outputCost - inputCost).toFixed(2);
    }

    const GetCraftingMaterialsList = () => {
        return AllCraftingMaterials.map(x => 
            x.List.map(y => (
                <option key={y.Name} value={y.Name}>{y.Name}</option>
            ))
        )
    }

    return <Main>
        <AuctionMarkupCalc />
        <ToolDivider />
        <CalcContainer>
            <h2>CRAFTING PROFIT CALCULATOR</h2>
            <InputOutputContainer>
                <InputContainer>
                    <span>INPUT MATERIALS</span>
                    {/* <span>Manual Input: <input type="checkbox" onClick={e => {
                        e.currentTarget.checked ? setManualInput(true) : setManualInput(false);
                    }} /></span> */}
                    <InputRows id="inputRows" className="inputRows">
                        <InputRow>
                            {manualInput 
                            ? (<><input type="text" maxLength={100} placeholder="Item Name" />
                            <input type="number" step={0.01} min={0} placeholder="TT value per item" /></>) 
                            :
                            <MaterialDropdown>{GetCraftingMaterialsList()}</MaterialDropdown>}
                            <input type="number" step={1} min={1} placeholder="Items Per Click" />
                            <input type="number" step={0.01} min={100} placeholder="Markup (default 100) %" />
                        </InputRow>
                    </InputRows>
                    <AddDeleteRows>
                        <h2 onClick={() => generateInputs(manualInput)}>+</h2>
                        <h2 onClick={() => generateInputs(manualInput, true)}>-</h2>
                    </AddDeleteRows>
                    <InputRow>
                        <span>{'AMOUNT OF CLICKS'}</span>
                        <input style={{marginBottom: '20px', width: '100%'}} onChange={e => setClickAmount(parseInt(e.target.value))} type="number" id="clickAmount" step={1} min={1} placeholder="Amount of Clicks" />
                    </InputRow>
                    <InputRow style={{marginTop: '8px', marginBottom: '25px'}}>
                        <span>TOTAL INPUT COST:</span>
                        <span>{inputCost} ped</span>
                    </InputRow>
                </InputContainer>
                <Output>
                    <span>CRAFTED OUTPUT</span>
                    <OutputRows id="outputRows" className="outputRows">
                        <OutputRow>
                            <input type="text" maxLength={100} placeholder="Item Name" />
                            <input type="number" min={1} placeholder="Item Count" />
                            <input type="number" step={0.01} min={0} placeholder="Total Crafted Item TT" />
                        </OutputRow>
                    </OutputRows>
                    <OutputRow style={{paddingTop: '10px', marginBottom: '15px'}}>
                        <span>{'Residue & Misc TT'}</span>
                        <input id="restValue" type="number" step={0.01} min={0} placeholder="Total TT Value" />
                    </OutputRow>
                    <OutputRow style={{paddingTop: '0px', marginBottom: '10px', paddingBottom: '20px', borderBottom: '1px solid white'}}>
                        <span>{'TOTAL TT OUTPUT:'}</span>
                        <span id="totOutputVal">0.00 ped</span>
                    </OutputRow>
                    <OutputRow style={{paddingTop: '0px', marginBottom: '20px'}}>
                        <span>{'RESULT:'}</span>
                        <span>{outputCost > 0 ? outputMarkup < 100 ? <span>TT PROFIT BY <span>{getTTProfit()}</span> ped</span> : <span>Markup to breakeven: {outputMarkup}%</span> : <span>N/A</span>}</span>
                    </OutputRow>
    
                </Output>
            </InputOutputContainer>
            <div>
                <ActionButton disabled={clickAmount <= 0} value="Calculate" onClick={() => {
                    if(!showResult) setShowResult(true);
                    setOutputMarkup(100);
                    setInputCost(caluclateInputCost());
                }}>CALCULATE</ActionButton>
            </div>
            <ResultContainer show={showResult}>
                <InputCalculations>
                    <InputUnitTableTitle>COST PER UNIT:</InputUnitTableTitle>
                    <InputUnitTable id="inputPerUnitTable">
                        <tbody id="costPerUnit">
                            <tr>
                                <th>NAME</th>
                                <th>CPU /w MU (ped)</th>
                            </tr>
                        </tbody>
                    </InputUnitTable>
                </InputCalculations>
                {/*<h2>Auction fee cost: <span>{auctionFeeCost} ped</span></h2>*/}
            </ResultContainer>
        </CalcContainer>
    </Main>
  }
