export const outpostsList = [
    { id: 1, name: 'Outpost', euX: 60716, euY: 73162, euZ: 105 },
    { id: 2, name: 'Outpost', euX: 59643, euY: 71550, euZ: 110 },
    { id: 3, name: 'Outpost', euX: 57919, euY: 70552, euZ: 109 },
    { id: 4, name: 'Outpost', euX: 62621, euY: 71859, euZ: 108 },
    { id: 5, name: 'Outpost', euX: 63816, euY: 69690, euZ: 112 },
    { id: 6, name: 'Outpost', euX: 61322, euY: 70258, euZ: 109 },
    { id: 7, name: 'Outpost', euX: 58758, euY: 69496, euZ: 110 },
    { id: 8, name: 'Outpost', euX: 59044, euY: 67863, euZ: 110 },
    { id: 9, name: 'Outpost', euX: 62248, euY: 66730, euZ: 109 },
    { id: 10, name: 'Outpost', euX: 62115, euY: 68003, euZ: 111 },
    { id: 11, name: 'Outpost', euX: 74053, euY: 71974, euZ: 156 },
    { id: 12, name: 'Outpost', euX: 78392, euY: 66780, euZ: 115 },
    { id: 13, name: 'Outpost', euX: 85351, euY: 95635, euZ: 317 },
    { id: 14, name: 'Outpost', euX: 89208, euY: 91605, euZ: 118 },
    { id: 15, name: 'Outpost', euX: 83538, euY: 93391, euZ: 172 },
    { id: 16, name: 'Outpost', euX: 83604, euY: 87212, euZ: 123 },
    { id: 17, name: 'Outpost', euX: 89042, euY: 83543, euZ: 112 },
    { id: 18, name: 'Outpost', euX: 75869, euY: 91456, euZ: 266 },
    { id: 19, name: 'Outpost', euX: 78890, euY: 93959, euZ: 183 },
    { id: 20, name: 'Outpost', euX: 78653, euY: 97211, euZ: 240 },
    { id: 21, name: 'Outpost', euX: 62779, euY: 75245, euZ: 118 },
    { id: 22, name: 'Nus Null Outpost', euX: 63151, euY: 75643, euZ: 103 },
    { id: 23, name: 'South Swamp Camp Outpost', euX: 62032, euY: 75549, euZ: 109 },
    { id: 24, name: 'Outpost', euX: 62712, euY: 76113, euZ: 102 },
    { id: 25, name: 'Outpost', euX: 61256, euY: 77037, euZ: 238 },
    { id: 26, name: 'Outpost', euX: 69804, euY: 86981, euZ: 140 },
    { id: 27, name: 'Outpost', euX: 72300, euY: 86407, euZ: 240 },
    { id: 28, name: 'Outpost', euX: 68988, euY: 82348, euZ: 159 },
    { id: 29, name: 'Outpost', euX: 65960, euY: 81272, euZ: 482 },
    { id: 30, name: 'Outpost', euX: 66398, euY: 76728, euZ: 104 },
    { id: 31, name: 'Outpost', euX: 71191, euY: 74151, euZ: 289 },
    { id: 32, name: 'Eurus Outpost', euX: 73267, euY: 76008, euZ: 210 },
    { id: 33, name: 'Outpost', euX: 65964, euY: 81288, euZ: 480 },
    { id: 34, name: 'Outpost', euX: 61937, euY: 85288, euZ: 318 },
    { id: 35, name: 'Outpost', euX: 69680, euY: 73066, euZ: 223 },
    { id: 36, name: 'Outpost', euX: 68969, euY: 67710, euZ: 407 },
    { id: 37, name: 'Outpost', euX: 68809, euY: 69242, euZ: 497 },
    { id: 38, name: 'Outpost', euX: 70395, euY: 69680, euZ: 378 },
    { id: 39, name: 'Outpost', euX: 71904, euY: 72532, euZ: 123 },
    { id: 40, name: 'Outpost', euX: 72019, euY: 71127, euZ: 223 },
    { id: 41, name: 'Outpost', euX: 73409, euY: 72807, euZ: 130 },
    { id: 42, name: 'Outpost', euX: 78392, euY: 66780, euZ: 115 },
    { id: 43, name: 'Outpost', euX: 74053, euY: 71974, euZ: 156 },
    { id: 44, name: 'Camp Carter Outpost', euX: 77140, euY: 68681, euZ: 151 },
    { id: 45, name: 'Cyclop\'s Depths', euX: 76876, euY: 70976, euZ: 477 },
    { id: 46, name: 'Outpost', euX: 79104, euY: 71575, euZ: 168 },
    { id: 47, name: 'Outpost', euX: 80270, euY: 72397, euZ: 168 },
    { id: 48, name: 'Outpost', euX: 80617, euY: 70477, euZ: 147 },
    { id: 49, name: 'Outpost', euX: 35950, euY: 54758, euZ: 258 },
    { id: 50, name: 'CDF Outpost Delta', euX: 60253, euY: 84579, euZ: 112 },
    { id: 51, name: 'Livas Outpost', euX: 64729, euY: 85111, euZ: 294 },
    { id: 52, name: 'Cave (Bristlehog)', euX: 64814, euY: 87275, euZ: 320 }, // TODO: Move to instance
    { id: 53, name: 'Tidewater Caverns (Pupigi/SonicSquid/Snarg/Oculus/TskTsk)', euX: 62384, euY: 89579, euZ: 121 }, // TODO: Move to instance
    { id: 54, name: 'Outpost', euX: 80422, euY: 74584, euZ: 108 },
    { id: 55, name: 'Outpost', euX: 77297, euY: 74137, euZ: 100 },
    { id: 56, name: 'Outpost', euX: 74772, euY: 75085, euZ: 159 },
    { id: 57, name: 'Outpost', euX: 79802, euY: 74716, euZ: 101 },
    { id: 58, name: 'Outpost', euX: 80828, euY: 78098, euZ: 132 },
    { id: 59, name: 'Outpost', euX: 75646, euY: 77129, euZ: 235 },
    { id: 60, name: 'Outpost', euX: 74479, euY: 89271, euZ: 132 },
    { id: 61, name: 'Outpost', euX: 83738, euY: 83556, euZ: 230 },
    { id: 62, name: 'Outpost', euX: 88351, euY: 75357, euZ: 202 },
    { id: 63, name: 'Robot Outpost', euX: 88567, euY: 79516, euZ: 310 },
    { id: 64, name: 'Outpost', euX: 87968, euY: 81057, euZ: 216 },
    { id: 65, name: 'Robot Outpost', euX: 82661, euY: 76903, euZ: 176 },
    { id: 66, name: 'Outpost', euX: 83201, euY: 75295, euZ: 204 },
    { id: 67, name: 'Outpost', euX: 83906, euY: 80575, euZ: 251 },
    { id: 69, name: 'Outpost', euX: 83874, euY: 78164, euZ: 156 },
    { id: 70, name: 'Fortress Invincible', euX: 85499, euY: 76802, euZ: 222 },
    { id: 71, name: 'Fortress Invincible', euX: 84230, euY: 76604, euZ: 211 },
    { id: 72, name: 'Outpost', euX: 60079, euY: 70059, euZ: 109 },
    { id: 73, name: 'CDF Outpost Epsilon', euX: 60905, euY: 82727, euZ: 111 },
    { id: 74, name: 'Outpost', euX: 26106, euY: 60556, euZ: 115 },
    { id: 75, name: 'Outpost', euX: 72430, euY: 81311, euZ: 115 },
    { id: 76, name: 'CDF Outpost APC', euX: 39449, euY: 30802, euZ: 105 },
    { id: 77, name: 'Outpost', euX: 66352, euY: 70948, euZ: 180 },
    { id: 78, name: 'Tethys Creek Outpost', euX: 67110, euY: 88510, euZ: 109 },
    { id: 79, name: 'Outpost', euX: 59469, euY: 81065, euZ: 401 },
    { id: 80, name: 'Outpost', euX: 77152, euY: 93991, euZ: 717 },
    { id: 81, name: 'Outpost', euX: 25938, euY: 30339, euZ: 112 },
    { id: 82, name: 'Tresutte Outpost', euX: 29039, euY: 28980, euZ: 112 },
    { id: 83, name: 'Outpost', euX: 30660, euY: 28705, euZ: 102 },
    { id: 84, name: 'Outpost', euX: 68965, euY: 85619, euZ: 156 },
    { id: 85, name: 'Outpost', euX: 75594, euY: 66693, euZ: 106 },
    { id: 86, name: 'Outpost', euX: 87419, euY: 95424, euZ: 476 },
    { id: 87, name: 'Outpost', euX: 82422, euY: 90715, euZ: 434 },
    { id: 88, name: 'Outpost', euX: 67465, euY: 70881, euZ: 551 },
    { id: 89, name: 'Outpost', euX: 66746, euY: 73144, euZ: 271 },
    { id: 90, name: 'Outpost', euX: 78433, euY: 78049, euZ: 147 },
    { id: 91, name: 'Outpost', euX: 65414, euY: 77938, euZ: 147 },

    { id: 999999, name: 'Outpost', x: 9999999, y: 9999999, euX: 9999999, euY: 9999999, euZ: 9999999 },
];